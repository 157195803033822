import {Thesaurus} from 'api/types';
import {formatNodeTitle} from 'services/thesaurus/functions/formatNodeTitle';
import {findThesaurusNode} from 'schema/table/functions/findThesaurusNode';

export const mapThesaurusValues = (
  thesaurus: Thesaurus,
  values: string[] | undefined,
) => {
  const valueNodes = values?.map(cV => {
    return findThesaurusNode(cV, thesaurus);
  });

  return valueNodes?.filter(v => v).map(node => formatNodeTitle(node!));
};
