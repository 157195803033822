import React, {useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {BulkUpdate, BulkUpdateItemStatus} from 'api/types';
import {Data} from 'schemaDefinition/types';
import {WorkItemLink} from 'services/workItems/types';
import {useSetAdvancedSearchItems} from 'services/advancedSearchItems';
import {useSetWorkItems} from 'services/workItems';
import {Layout, Spacer} from 'components';
import {Preview, Table} from 'schema';
import {useBulkUpdateSchema} from 'schemas/schemas/hooks';
import {useBulkUpdateCreateSchema} from 'schemas/schemas/hooks/useBulkUpdateCreateSchema';
import {useSchema} from '../../schemas';
import {BulkUpdateTableHeader} from './BulkUpdateTableHeader';
import {addErrorPartToSchema} from './functions';
import {mapErrorsToData} from './functions/mapErrorsToData';
import {useDataByIdPagination} from './hooks/useDataByIdPagination';
import {failedBulkUpdateTableConfiguration} from './schema/failedBulkUpdateTableConfiguration';

export type GetWorkItemLink = (
  id: string,
  status: BulkUpdateItemStatus,
) => WorkItemLink;

type Props<TData extends Data = Data> = {
  bulkUpdate: BulkUpdate;
  getWorkItemLink: GetWorkItemLink;
  getItemId: (data: TData) => string;
  loader: (ids: string[]) => Promise<TData[]>;
};

export function BulkUpdatePreviewCore<TData extends Data = Data>({
  bulkUpdate,
  getWorkItemLink,
  getItemId,
  loader,
}: Props<TData>): React.ReactElement {
  const navigate = useNavigate();
  const bulkUpdateCreateSchema = useBulkUpdateCreateSchema(bulkUpdate);
  const bulkUpdateSchema = useBulkUpdateSchema(bulkUpdate);
  const schema = useSchema(bulkUpdate.type);

  const setSearchItems = useSetAdvancedSearchItems();
  const setWorkItems = useSetWorkItems();

  const handleOpenInSearch = useCallback(
    (itemIds: string[]) => {
      setSearchItems({type: bulkUpdate.type, itemIds});
      navigate(`/search?workIds=${itemIds.join(',')}`);
    },
    [bulkUpdate.type, navigate, setSearchItems],
  );

  const handleClickItem = useCallback(
    (item: TData, ids: string[], status: BulkUpdateItemStatus) => {
      const itemId = getItemId(item);

      if (getWorkItemLink) {
        const workLinks = ids
          .filter(id => id !== itemId)
          .map(id => getWorkItemLink(id, status));
        const itemLink = getWorkItemLink(itemId, status);
        setWorkItems(workLinks);
        navigate(itemLink.link);
      }
    },
    [getItemId, getWorkItemLink, navigate, setWorkItems],
  );

  const isNew = bulkUpdate.status === 'NEW';
  const showOpenInSearch = bulkUpdate.status === 'COMPLETED';

  const failedIds = useMemo(
    () => bulkUpdate.items.failed.map(item => item.id),
    [bulkUpdate.items.failed],
  );

  const failedPagination = useDataByIdPagination<TData>(failedIds, loader);

  const failedData = useMemo(() => {
    return mapErrorsToData(failedPagination.data, bulkUpdate.items.failed);
  }, [failedPagination.data, bulkUpdate.items.failed]);

  const failedSchema = useMemo(() => {
    return addErrorPartToSchema(schema);
  }, [schema]);

  const successPagination = useDataByIdPagination<TData>(
    bulkUpdate.items.succeeded,
    loader,
  );
  const unprocessedPagination = useDataByIdPagination<TData>(
    bulkUpdate.items.unprocessed,
    loader,
  );

  return (
    <Layout>
      <Preview
        schema={isNew ? bulkUpdateCreateSchema : bulkUpdateSchema}
        data={bulkUpdate}
      />
      <Spacer height={2} />
      {bulkUpdate.items.failed.length > 0 ? (
        <>
          <Table<TData>
            {...failedPagination}
            data={failedData}
            schema={failedSchema}
            configuration={failedBulkUpdateTableConfiguration}
            header={
              <BulkUpdateTableHeader
                type={bulkUpdate.type}
                itemStatus={'FAILED'}
                itemIds={failedIds}
                showOpenInSearch={showOpenInSearch}
                onSearch={handleOpenInSearch}
              />
            }
            onRowClick={
              isNew
                ? undefined
                : item => handleClickItem(item, failedIds, 'FAILED')
            }
          />
          <Spacer height={2} />
        </>
      ) : null}

      {bulkUpdate.items.unprocessed.length > 0 ? (
        <>
          <Table
            {...unprocessedPagination}
            schema={schema}
            header={
              <BulkUpdateTableHeader
                type={bulkUpdate.type}
                itemStatus={isNew ? 'NEW' : 'UNPROCESSED'}
                itemIds={bulkUpdate.items.unprocessed}
                showOpenInSearch={showOpenInSearch}
                onSearch={handleOpenInSearch}
              />
            }
            onRowClick={
              isNew
                ? undefined
                : item =>
                    handleClickItem(
                      item,
                      bulkUpdate.items.unprocessed,
                      isNew ? 'NEW' : 'UNPROCESSED',
                    )
            }
          />
          <Spacer height={2} />
        </>
      ) : null}
      {bulkUpdate.items.succeeded.length > 0 ? (
        <>
          <Table
            {...successPagination}
            schema={schema}
            header={
              <BulkUpdateTableHeader
                type={bulkUpdate.type}
                itemStatus={'SUCCEEDED'}
                itemIds={bulkUpdate.items.succeeded}
                showOpenInSearch={showOpenInSearch}
                onSearch={handleOpenInSearch}
              />
            }
            onRowClick={
              isNew
                ? undefined
                : item =>
                    handleClickItem(
                      item,
                      bulkUpdate.items.succeeded,
                      'SUCCEEDED',
                    )
            }
          />
          <Spacer height={2} />
        </>
      ) : null}
    </Layout>
  );
}
