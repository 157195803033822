import {Concept} from 'types';
import {valueReducerFactory} from 'services/utils/redux/valueReducerFactory';
import {BulkUpdateItemsState} from '../types';

const [
  BULK_UPDATE_ITEMS_KEY,
  bulkUpdateItemsReducer,
  setBulkUpdateItems,
  clearBulkUpdateItems,
  useBulkUpdateItems,
] = valueReducerFactory(`bulkUpdateItems`, {
  type: Concept.work,
  itemIds: [
    //  TODO: Remove these
    //'142e795d-b0e3-4751-9231-82dab382b069',
    //'06fc5ee6-a9d4-4308-8274-f933e90a4473',
  ],
} as BulkUpdateItemsState);

export type BulkUpdateItemsAction =
  | ReturnType<typeof setBulkUpdateItems>
  | ReturnType<typeof clearBulkUpdateItems>;

export {
  BULK_UPDATE_ITEMS_KEY,
  bulkUpdateItemsReducer,
  setBulkUpdateItems,
  clearBulkUpdateItems,
  useBulkUpdateItems,
};
