import {formatDateNo} from 'localization/core/formatting/formatDateNo';
import {BulkUpdate} from '../types';
import {Schemas} from '../dto.generated';
import {mapBulkUpdateActionDto} from './mapBulkUpdateActionDto';
import {mapBulkUpdateWorkField} from './mapBulkUpdateWorkField';

export const mapBulkUpdateDto = (
  id: string,
  dto: Schemas.BulkUpdateStatusResponse,
): BulkUpdate => {
  return {
    id: id,
    status: dto.status,
    items: dto.items,
    ...mapBulkUpdateWorkField(dto.field),
    ...mapBulkUpdateActionDto(dto.action),
    created: formatDateTime(dto.created),
    completed: dto.completed ? formatDateTime(dto.completed) : undefined,
  };
};

const formatDateTime = (timestamp: number): string => {
  return formatDateNo(timestamp * 1000, 'short', true);
};
