import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {clearAdvancedSearchItems} from '../actions';

export const useClearAdvancedSearchItems = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(clearAdvancedSearchItems());
  }, [dispatch]);
};
