import {useEffect, useState} from 'react';
import {getBulkUpdates} from 'api/bulkUpdate';
import {useGetTokens} from 'services/auth';

export const useBulkUpdateInProgress = () => {
  const getTokens = useGetTokens();
  const [inProgress, setInProgress] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    getBulkUpdates(getTokens, 0, 5).then(r => {
      setInProgress(r.items.some(i => !i.completed));
    });
  }, [getTokens]);

  return inProgress;
};
