import assert from 'assert-ts';
import {BulkUpdateEntityType, Concept, MetadataId} from 'types';

export const mapMetadataIdByType = (
  type: BulkUpdateEntityType,
  metadataIds: MetadataId[],
): string[] => {
  switch (type) {
    case Concept.work:
      return metadataIds.map(id =>
        assert(id.workId, 'mapMetadataIdByType: Expected workId'),
      );
    case Concept.manifestation:
      return metadataIds.map(id =>
        assert(
          id.manifestationId,
          'mapMetadataIdByType: Expected manifestationId',
        ),
      );
  }
};
