import {useCallback, useMemo, useState} from 'react';
import assert from 'assert-ts';
import {
  PAGESIZE_DEFAULT,
  PAGINATION_PAGE_START,
  PageSizeProps,
  PaginationState,
} from 'components/paginator/types';

export const usePaginationState = (
  defaultSize: number,
  defaultPageSizes?: PageSizeProps['pageSizes'],
  defaultTotal?: number,
): PaginationState => {
  const [page, setPage] = useState<number>(PAGINATION_PAGE_START);
  const [total, setTotal] = useState<number>(defaultTotal ?? 0);
  const [size, setSize] = useState<number>(defaultSize);

  const pageSizes = useMemo(() => {
    return defaultPageSizes ?? PAGESIZE_DEFAULT;
  }, [defaultPageSizes]);

  const resetPage = useCallback((total: number, page?: number) => {
    assert(
      !page || page > 0,
      'usePaginationState: resetPage(..., page) should be 1 or higher',
    );
    setPage(page ? page : PAGINATION_PAGE_START);
    setTotal(total);
  }, []);

  const pages = useMemo(() => {
    return total ? Math.ceil(total / size) : 1;
  }, [total, size]);

  const handleNextPage = useCallback(() => {
    if (page < pages) {
      setPage(page + 1);
    }
  }, [page, pages]);

  const handlePreviousPage = useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page]);

  const handleSetSize = useCallback(
    (size: number) => {
      setSize(size);
      resetPage(total);
    },
    [resetPage, total],
  );

  return useMemo(() => {
    return {
      page,
      pages,
      size,
      total,
      setSize: handleSetSize,
      onSelectNext: handleNextPage,
      onSelectPrevious: handlePreviousPage,
      resetPage,
      setPage,
      pageSizes,
    };
  }, [
    page,
    pages,
    size,
    total,
    handleSetSize,
    handleNextPage,
    handlePreviousPage,
    resetPage,
    setPage,
    pageSizes,
  ]);
};
