import {TableConfiguration} from 'schema/table/types';
import {defaultTableConfig} from 'schema/table/configuration/defaultTableConfig';
import {BulkUpdateErrorMessage} from './renderer';

export const failedBulkUpdateTableConfiguration: TableConfiguration = {
  ...defaultTableConfig,
  partValueRenderers: {
    errorMessage: BulkUpdateErrorMessage,
  },
};
