import React, {useCallback} from 'react';
import {PartValueRenderer} from 'schema/types';
import {useLocalization} from 'localization';
import {useSetWorkItems} from 'services/workItems';
import {createMetadataLink} from 'services/workItems/functions';
import {CopyToClipboard, Link} from 'components';
import {useAdvancedSearchContext} from '../../../context/AdvancedSearchContext';
import {isEanOrIsbn, isUuid} from '../../../functions';

/**
 * Can only be used for ean/isbn and workId. Not manifestationId because we cannot
 * know wheather the uuid is workId or manifestationId.
 */
export const MetadataLink: PartValueRenderer = ({value}) => {
  const {t} = useLocalization();
  const setWorkItems = useSetWorkItems();
  const {getMetadataIdsPage} = useAdvancedSearchContext();

  const firstValue =
    Array.isArray(value) && value.length > 0 ? value[0] : value;

  // Clicked value.
  const valueAsLink = isEanOrIsbn(firstValue)
    ? createMetadataLink({ean: firstValue})
    : isUuid(firstValue)
      ? createMetadataLink({workId: firstValue})
      : undefined;

  const onClick = useCallback(() => {
    setWorkItems([]);

    // Initiates promise, which will update redux with identifiers,
    // after the backend-call is completed.
    getMetadataIdsPage(0, 200).then(allMetadataIds => {
      const allLinks = (allMetadataIds.map(createMetadataLink) ?? []).filter(
        l => l,
      ) as string[]; // Remove empty

      // Remove all items before the one we clicked.
      const currentIndex = allLinks.findIndex(l => l === valueAsLink);
      // +1 to remove the one we navigated to.
      allLinks.splice(0, currentIndex + 1);

      setWorkItems(
        allLinks.map(link => ({
          link,
          tooltip: t(`page.search.next.tooltip`),
        })),
      );
    });
  }, [getMetadataIdsPage, setWorkItems, t, valueAsLink]);

  if (!firstValue || typeof firstValue !== 'string') {
    return null;
  }

  return (
    <>
      <Link
        title={String(firstValue)}
        to={valueAsLink ?? ''}
        onClick={onClick}
      />

      <CopyToClipboard text={String(firstValue)} size={'small'} />
    </>
  );
};
