import {BulkUpdateSummary} from 'api/types';
import {TableConfiguration} from 'schema/table/types';
import {defaultTableConfig} from 'schema/table/configuration/defaultTableConfig';

export const bulkUpdateSummaryTableConfig: TableConfiguration<BulkUpdateSummary> =
  {
    ...defaultTableConfig,
    disableSorting: true,
    partValueRenderers: {},
  };
