import {TypedSchema} from 'schemaDefinition/types';
import {BulkUpdate, BulkUpdateCreate} from 'api';

export const bulkUpdateCreateStaticSchema: TypedSchema<BulkUpdateCreate> = {
  name: 'fieldAndOperationSchema',
  parts: [
    {
      type: 'codelist',
      name: 'bulkUpdateField',
      codelistId: 'bulkUpdateWorkField',
      required: true,
      cardinality: 'single',
    },
    {
      type: 'codelist',
      name: 'bulkUpdateAction',
      codelistId: 'bulkUpdateAction',
      required: true,
      cardinality: 'single',
    },
    {
      type: 'bool',
      name: 'addToFirstPosition',
    },
  ],
};

export const bulkUpdateStaticSchema: TypedSchema<BulkUpdate> = {
  name: 'bulkUpdateStaticSchema',
  parts: [
    {
      type: 'codelist',
      name: 'status',
      codelistId: 'bulkUpdateStatus',
    },
    {
      type: 'text',
      name: 'created',
    },
    {
      type: 'text',
      name: 'completed',
    },
    ...bulkUpdateCreateStaticSchema.parts,
  ],
};
