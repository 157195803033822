import {useCallback} from 'react';
import {ThesaurusId} from 'api/types';
import {useThesaurus} from 'services/thesaurus';
import {mapThesaurusValues} from '../function/mapThesaurusValues';

export const useMapThesaurusValues = (thesaurusId: ThesaurusId) => {
  const thesaurus = useThesaurus(thesaurusId);

  return useCallback(
    (values?: string[]) => {
      return mapThesaurusValues(thesaurus, values);
    },
    [thesaurus],
  );
};
