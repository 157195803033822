import {useMemo} from 'react';
import {bulkUpdateStaticSchema} from '../bulkUpdateSchemas';
import {
  BulkUpdateValuesProps,
  useBulkUpdateValuesSchema,
} from './useBulkUpdateValuesSchema';

export const useBulkUpdateSchema = (props: BulkUpdateValuesProps) => {
  const valuesSchema = useBulkUpdateValuesSchema(props);
  return useMemo(
    () => ({
      name: 'bulkUpdate',
      parts: [...bulkUpdateStaticSchema.parts, ...valuesSchema.parts],
    }),
    [valuesSchema],
  );
};
