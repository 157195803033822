import React, {BaseSyntheticEvent, useCallback, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {
  AppBar,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Popover,
  Stack,
  SxProps,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';
import {FullManifestation} from 'api/types';
import {Configuration, isFeatureEnabled} from 'configuration';
import {useLocalization} from 'localization';
import {px2rem, useThemeColors} from 'theme';
import {ThemeColors} from 'theme/themeColors';
import {useUser} from 'services/auth';
import {
  SettingsAction_AdjustFont,
  SettingsAction_ResetFont,
  SettingsAction_SetLocale,
} from 'services/settings';
import {useShortcut} from 'shortcuts';
import {SystemHealthWidget} from 'widgets';
import {ActivityIndicator} from '../activityIndicator';
import {Avatar} from '../avatar';
import {MenuButton} from '../button';
import {Link} from '../button/Link';
import {TextField} from '../fields';
import {LessIcon, MoreIcon, RadioOnIcon, SearchIcon} from '../icons';
import {FlexBox, Layout} from '../layout';
import {Spacer} from '../spacer';
import {Logo} from '../svg';
import {TopBarAnon} from './TopbarAnon';
import {ShortcutsTooltip} from './components/ShortcutsTooltip';
import {SearchError, useGlobalSearch} from './hooks/useGlobalSearch';

type Props = {
  role: 'user' | 'admin';
};

const isCurrentPath = (navPath: string, currentPathName: string) => {
  const currentPath =
    currentPathName[0] === '/' ? currentPathName.substring(1) : currentPathName;
  const firstElement = currentPath.split('/')[0];
  return navPath === firstElement;
};

const appbarSx: SxProps<Theme> = {
  backgroundClip: 'padding-box',
  zIndex: theme => theme.zIndex.drawer + 1,
};

const envBannerSx = {
  transform: 'translateX(-30px) rotate(-45deg) scaleX(2) scaleY(1.5)',
  background: Configuration.env === 'stage' ? '#FF0C' : '#F00C',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  pointerEvents: 'none',
} as const;

const originBottomLeft = {
  vertical: 'bottom',
  horizontal: 'left',
} as const;

const originTopRight = {
  vertical: 'top',
  horizontal: 'right',
} as const;

export const TopBar: React.FC<Props> = () => {
  const {t, tLoose, getPreferredLocale} = useLocalization();
  const themeColors = useThemeColors();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useUser();
  useShortcut('goToOverview', () => navigate('/overview'));

  const {toolbarSx, searchButtonSx, menuButtonSx, menuSx} = useMemo(() => {
    const toolbarSx: SxProps<Theme> = {
      paddingX: 3,
      justifyContent: 'space-between',
      background: ThemeColors.background.topBar.main,
    };
    const searchButtonSx: SxProps<Theme> = {
      p: 0.5,
      color: themeColors.text.primary.light,
    };
    const menuButtonSx: SxProps<Theme> = {
      p: 0.5,
      color: themeColors.text.primary.dark,
    };
    const menuSx: SxProps<Theme> = {mt: px2rem(45)};

    return {toolbarSx, searchButtonSx, menuButtonSx, menuSx};
  }, [themeColors]);

  const pages = useMemo(() => {
    const p = {
      user: [
        {title: t('appbar.overview'), path: 'overview'},
        isFeatureEnabled('advancedSearch')
          ? {title: t('appbar.advancedSearch'), path: 'search'}
          : null,

        {title: t('appbar.agents'), path: 'agent'},
      ].filter(page => page !== null) as {title: string; path: string}[],
      // admin: [],
      settings: [
        {
          title: `${t('appbar.menu.language')}: ${tLoose(
            'general.language.' + getPreferredLocale(),
          )}`,
          action: 'toggleLanguage',
        },
        {title: t('appbar.menu.profile'), action: 'gotoProfile'},
        {title: t('appbar.menu.account'), action: 'gotoAccount'},
        {title: t('appbar.menu.logout'), action: 'logout'},
      ],
    };

    if (isFeatureEnabled('bulkUpdate')) {
      p.user.push({title: t('appbar.bulkUpdate'), path: 'bulkUpdate'});
    }

    if (isFeatureEnabled('receiveRoute')) {
      p.user.push({title: t('appbar.receive'), path: 'receive'});
    }

    if (Configuration.env === 'local' || Configuration.env === 'dev') {
      p.user.push({title: t('appbar.demo'), path: 'demo'});
      p.user.push({title: t('appbar.demoSearch'), path: 'demoSearch'});
    }
    return p;
  }, [t, tLoose, getPreferredLocale]);

  const [searchText, setSearchText] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchError, setSearchError] = React.useState<SearchError | undefined>(
    undefined,
  );
  const [anchorElSearch, setAnchorElSearch] = React.useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const [anchorElUser, setAnchorElUser] = React.useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenSearchMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElSearch(event.currentTarget);
  };
  const handleCloseSearchMenu = () => {
    setAnchorElSearch(null);
  };

  const handleUserMenuItem = useCallback(
    (title: string) => {
      switch (title) {
        case 'toggleLanguage': {
          const action: SettingsAction_SetLocale = {
            type: 'SET_LOCALE',
            payload: {
              preferredLocale: getPreferredLocale() === 'en' ? 'nb' : 'en',
            },
          };
          dispatch(action);
          break;
        }
        case 'gotoProfile': {
          break;
        }
        case 'gotoAccount': {
          break;
        }
        case 'logout': {
          navigate('/logout');
          break;
        }
      }

      handleCloseUserMenu();
    },
    [dispatch, getPreferredLocale, navigate],
  );

  const handleSmallerFont = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const action: SettingsAction_AdjustFont = {
        type: 'ADJUST_FONT',
        payload: {
          changePercentPoint: -5,
        },
      };
      dispatch(action);
      event.preventDefault();
      event.stopPropagation();
    },
    [dispatch],
  );

  const handleResetFont = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const action: SettingsAction_ResetFont = {
        type: 'RESET_FONT',
      };
      dispatch(action);
      event.preventDefault();
      event.stopPropagation();
    },
    [dispatch],
  );

  const handleLargerFont = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const action: SettingsAction_AdjustFont = {
        type: 'ADJUST_FONT',
        payload: {
          changePercentPoint: 5,
        },
      };
      dispatch(action);
      event.preventDefault();
      event.stopPropagation();
    },
    [dispatch],
  );

  const {search, navigateToManifestation} = useGlobalSearch();

  if (!user) {
    return <TopBarAnon />;
  }

  const searchIsOpen = Boolean(anchorElSearch);
  const anchorElSearchId = searchIsOpen ? 'simple-popover' : undefined;

  const onSearch = () => {
    setSearchLoading(true);

    const response = search(searchText);
    if (!response || response === 'notFound' || response === 'invalidLength') {
      setSearchError(response);
      setSearchLoading(false);
      return;
    }

    response.then(body => {
      if (!body || body === 'notFound' || body === 'invalidLength') {
        setSearchError(body || 'notFound');
        setSearchLoading(false);
        return;
      }

      setSearchLoading(false);
      handleCloseSearchMenu();
      navigateToManifestation(body as FullManifestation);
    });
  };

  return (
    <AppBar data-cy="main-app-bar" sx={appbarSx}>
      <Toolbar disableGutters sx={toolbarSx}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <FlexBox position={'relative'}>
            {Configuration.env !== 'prod' ? (
              <FlexBox position={'absolute'} center sx={envBannerSx}>
                <FlexBox center bottom>
                  <Typography variant={'caption'}>
                    {Configuration.env === 'stage'
                      ? 'STG'
                      : Configuration.env === 'dev'
                        ? 'DEV'
                        : 'LOCAL'}
                  </Typography>
                </FlexBox>
              </FlexBox>
            ) : null}
            <Link to={'/'} title={'Ebba'}>
              <Logo color={themeColors.background.topBar.light} />
            </Link>
          </FlexBox>
          {pages.user.map(page => (
            <MenuButton
              key={page.path}
              path={page.path}
              title={page.title}
              selected={isCurrentPath(page.path, location.pathname)}
            />
          ))}
        </Stack>

        <Stack direction="row" spacing={2} alignItems="center">
          <Spacer size={4} />
          <ShortcutsTooltip />
          <SystemHealthWidget />
          <IconButton
            className={'cy-search-head'}
            aria-describedby={anchorElSearchId}
            onClick={handleOpenSearchMenu}
            sx={searchButtonSx}>
            <SearchIcon />
          </IconButton>
          <Popover
            id={anchorElSearchId}
            open={searchIsOpen}
            anchorEl={anchorElSearch}
            onClose={() => handleCloseSearchMenu()}
            anchorOrigin={originBottomLeft}>
            <Layout padding={1}>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  onSearch();
                }}>
                <Typography
                  variant={'label'}
                  color={searchError !== undefined ? 'red' : undefined}>
                  {t(`error.search.${searchError}`)}
                </Typography>
                <TextField
                  type={'text'}
                  name={'search'}
                  value={searchText}
                  placeholder={'ISBN/EAN'}
                  onChange={(e: BaseSyntheticEvent) => {
                    setSearchError(undefined);
                    setSearchText(
                      // Only enter numbers
                      e.target.value ? e.target.value.replace(/\D/g, '') : '',
                    );
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => onSearch()}>
                          {searchLoading ? (
                            <ActivityIndicator
                              color="inherit"
                              size={'medium'}
                            />
                          ) : (
                            <SearchIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </Layout>
          </Popover>
          <IconButton onClick={handleOpenUserMenu} sx={menuButtonSx}>
            <Avatar src={user.picture} />
          </IconButton>
          <Menu
            sx={menuSx}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={originTopRight}
            keepMounted
            transformOrigin={originTopRight}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}>
            <MenuItem>
              <FlexBox>
                <Typography textAlign="center">
                  {t('appbar.menu.fontSize')}
                </Typography>
                <FlexBox horizontal px={1}>
                  <IconButton onClick={handleSmallerFont}>
                    <LessIcon />
                  </IconButton>
                  <IconButton onClick={handleResetFont}>
                    <RadioOnIcon />
                  </IconButton>
                  <IconButton onClick={handleLargerFont}>
                    <MoreIcon />
                  </IconButton>
                </FlexBox>
              </FlexBox>
            </MenuItem>
            {pages.settings.map(setting => (
              <MenuItem
                key={setting.action}
                onClick={() => handleUserMenuItem(setting.action)}>
                <Typography textAlign="center">{setting.title}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
