import {useCallback, useMemo, useState} from 'react';
import {assert} from 'assert-ts';
import {BulkUpdateEntityType} from 'types';
import {BulkUpdateCreate} from 'api/types';
import {Schema} from 'schemaDefinition/types';
import {useCodelistsLazy} from 'services/codeLists';
import {useSchemaCodelistIds} from 'schema';
import {validate} from 'schema/form/functions/validators/validate';
import {useBulkUpdateCreateSchema} from 'schemas/schemas/hooks';
import {STEP_IDS, StepId} from '../types';

/**
 * Edit new bulk update
 *   - Steps:
 *     - Step 1: Choose field and operation type (add, remove, replace), edit value(s) to add, remove or replace
 *     - Step 2: Review and submit
 */

export type NewBulkUpdateWizardProps = {
  bulkUpdateType: BulkUpdateEntityType;
  itemIds: string[];
  onCancel: () => void;
  onSubmit: (
    bulkUpdateState: BulkUpdateCreate,
    itemIds: string[],
  ) => Promise<void>;
};

type NewBulkUpdateWizardState = {
  stepIds: StepId[];
  currentStep: StepId;
  bulkUpdateType: BulkUpdateEntityType;
  bulkUpdateState: Partial<BulkUpdateCreate>;
  bulkUpdateSchema: Schema;
  onBulkUpdateStateEdited: (value: Partial<BulkUpdateCreate>) => void;
  showValidationErrors: boolean;
  gotoStep: (stepId: StepId) => void;
  onSave: () => Promise<void>;
};

export const useNewBulkUpdateWizardState = ({
  bulkUpdateType,
  itemIds,
  onSubmit,
}: NewBulkUpdateWizardProps): NewBulkUpdateWizardState => {
  const [currentStep, setCurrentStep] = useState<StepId>('STEP_1_EnterValues');

  const [newBulkUpdateState, setNewBulkUpdateState] = useState<
    Partial<BulkUpdateCreate>
  >({
    type: bulkUpdateType,
  });
  const [showValidationErrors, setShowValidationErrors] = useState(false);

  const bulkUpdateDataSchema = useBulkUpdateCreateSchema(newBulkUpdateState);

  const codelistIds = useSchemaCodelistIds(bulkUpdateDataSchema);
  const codelistMap = useCodelistsLazy(codelistIds);

  const handleBulkUpdateStateEdited = useCallback(
    (value: Partial<BulkUpdateCreate>) => {
      setNewBulkUpdateState(prev =>
        // Reset values if field to update changes
        value.bulkUpdateField !== prev.bulkUpdateField
          ? {
              ...prev,
              ...value,
              currentValue: undefined,
              newValue: undefined,
            }
          : {
              ...prev,
              ...value,
            },
      );
    },
    [],
  );

  const handleGotoStep = useCallback(
    (stepId: StepId) => {
      if (stepId === currentStep) {
        return;
      }

      switch (stepId) {
        case 'STEP_1_EnterValues':
          setCurrentStep('STEP_1_EnterValues');
          break;
        case 'STEP_2_Confirm':
          setCurrentStep('STEP_2_Confirm');
          break;
      }
    },
    [currentStep],
  );

  const handleSubmitBulkUpdate = useCallback((): Promise<void> => {
    const validation = validate(
      newBulkUpdateState,
      {},
      bulkUpdateDataSchema,
      assert(codelistMap, 'useNewBulkUpdateWizard: codelistMap is required'),
    );

    if (validation.valid !== 'valid') {
      setShowValidationErrors(true);
      return Promise.resolve();
    }

    return onSubmit(newBulkUpdateState as BulkUpdateCreate, itemIds);
  }, [
    newBulkUpdateState,
    bulkUpdateDataSchema,
    codelistMap,
    onSubmit,
    itemIds,
  ]);

  return useMemo(() => {
    const state: NewBulkUpdateWizardState = {
      stepIds: STEP_IDS,
      currentStep,
      bulkUpdateType: bulkUpdateType,
      bulkUpdateState: newBulkUpdateState,
      bulkUpdateSchema: bulkUpdateDataSchema,
      onBulkUpdateStateEdited: handleBulkUpdateStateEdited,
      showValidationErrors,
      gotoStep: handleGotoStep,
      onSave: handleSubmitBulkUpdate,
    };

    return state;
  }, [
    currentStep,
    bulkUpdateType,
    newBulkUpdateState,
    bulkUpdateDataSchema,
    handleBulkUpdateStateEdited,
    showValidationErrors,
    handleGotoStep,
    handleSubmitBulkUpdate,
  ]);
};
