import assert from 'assert-ts';
import {BulkUpdate, BulkUpdateCreate} from '../types';
import {Schemas} from '../dto.generated';

export const mapBulkUpdateActionDto = (
  dto: Schemas.BulkUpdateStatusResponse['action'],
): Pick<
  BulkUpdate,
  'bulkUpdateAction' | 'currentValue' | 'newValue' | 'addToFirstPosition'
> => {
  const addDto = dto as Schemas.BulkUpdateActionAdd;
  const removeDto = dto as Schemas.BulkUpdateActionRemove;
  const replaceDto = dto as Schemas.BulkUpdateActionReplace;

  switch (dto.action) {
    case 'ADD':
      return {
        bulkUpdateAction: 'ADD',
        newValue: [addDto.addValue],
        addToFirstPosition: addDto.addToFirstPosition,
      };
    case 'REMOVE':
      return {
        bulkUpdateAction: 'REMOVE',
        newValue: [removeDto.removeValue],
      };
    case 'REPLACE':
      return {
        bulkUpdateAction: 'REPLACE',
        currentValue: [replaceDto.currentValue],
        newValue: [replaceDto.updatedValue],
        addToFirstPosition: replaceDto.addToFirstPosition,
      };
  }

  assert(false, 'mapBulkUpdateActionDto: Expected action', dto);
};

export const mapToBulkUpdateActionDto = (
  dto: Pick<
    BulkUpdateCreate,
    'bulkUpdateAction' | 'currentValue' | 'newValue' | 'addToFirstPosition'
  >,
): Schemas.BulkUpdateWorkRequest['action'] => {
  switch (dto.bulkUpdateAction) {
    case 'ADD':
      return {
        action: 'ADD',
        addValue: assert(
          assert(dto.newValue, 'ADD: newValue expected')[0],
          'ADD: newValue[0] expected',
        ),
        addToFirstPosition: dto.addToFirstPosition,
      };
    case 'REMOVE':
      return {
        action: 'REMOVE',
        removeValue: assert(
          assert(dto.currentValue, 'REMOVE: currentValue expected')[0],
          'REMOVE: currentValue[0] expected',
        ),
      };
    case 'REPLACE':
      return {
        action: 'REPLACE',
        currentValue: assert(
          assert(dto.currentValue, 'REPLACE: currentValue expected')[0],
          'REPLACE: currentValue[0] expected',
        ),
        updatedValue: assert(
          assert(dto.newValue, 'REPLACE: newValue expected')[0],
          'REPLACE: newValue[0] expected',
        ),
        addToFirstPosition: dto.addToFirstPosition,
      };
  }

  assert(false, 'mapBulkUpdateActionDto: Expected action', dto);
};
