import {Filter, FilterOperation} from 'api/types';

export const filterIsSearchable = (filter: Filter) => {
  if (filter.generated) {
    return false;
  }

  // workId is handled in a separate parameter.
  if (['workId'].includes(filter.name)) {
    return false;
  }

  // EAN equal is handled in separate parameter
  if (['ean'].includes(filter.name) && filter.operation === 'EQUAL') {
    return false;
  }

  const allowedEmptyOperations: FilterOperation[] = ['NULL', 'NOT_NULL'];
  if (allowedEmptyOperations.includes(filter.operation)) {
    return true;
  }

  // Ignore filters without value
  return filter.values?.some(v => {
    return v.toString().trim().length > 0;
  });
};
