import {GetTokens, WorkHit} from 'api';
import {exceptionToPromiseReject} from 'api/exceptionToPromiseReject';
import {httpElastic} from 'api/http/search/httpElastic';
import {mapWorkHits} from 'api/mappers/mapWorkHits';
import {SearchSchemas} from 'api/search.dto.generated';
import {SearchResult, SearchResultDto} from 'api/searchTypes';

export const searchWorkConfig = (
  config: object,
  query: string,
  page: number,
  size: number,
  getTokens: GetTokens,
  signal?: AbortSignal,
  _fields?: string[],
): Promise<SearchResult<WorkHit>> => {
  const elasticQuery = JSON.stringify(config).replaceAll(
    '${QUERY_PLACEHOLDER}',
    query,
  );

  return exceptionToPromiseReject(() =>
    httpElastic<SearchResultDto<SearchSchemas.WorkDocument>>(
      getTokens,
      '_search',
      'catalog',
      JSON.parse(elasticQuery),
      page,
      size,
      0.1,
      undefined,
      signal,
    ).then(mapWorkHits),
  );
};
