import React, {useCallback, useEffect, useMemo} from 'react';
import {Grid} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {Box} from '@mui/system';
import {Concept, NoProps} from 'types';
import {CodeListId} from 'api/types';
import {isFeatureEnabled} from 'configuration';
import {useLocalization} from 'localization';
import {excludeBokbasenFilter, excludeInternationalFilter} from 'api/functions';
import {useCreateBulkUpdate} from 'services/bulkUpdateItems';
import {CodeListsGate, useAllCodeListIds} from 'services/codeLists';
import {ThesaurusesGate} from 'services/thesaurus';
import {unique} from 'services/utils';
import {ActionButton, Card, IconButton, Layout, Spacer, Text} from 'components';
import {Table} from 'schema';
import {advancedSearchTableConfig} from 'schemas';
import {
  Filter,
  FilterSets,
  HelpTooltip,
  SingleColumnSelectionAccordion,
} from './components';
import {CustomFilter} from './components/CustomFilter';
import {SelectViewMode} from './components/SelectViewMode';
import {useAdvancedSearchContext} from './context/AdvancedSearchContext';
import {findNextFilter} from './functions';
import {isNotCustomFilter} from './functions/assertAsCustomFilterType';
import {useCreateFilterDialog, useDownloadDialog} from './hooks';

type Props = NoProps;

export const AdvancedSearch: React.FC<Props> = () => {
  const {t} = useLocalization();
  const {
    currentFilters,
    addCurrentFilter,
    availableFilters,
    loading,
    onSearch,
    onDownload,
    searchResult,
    pagination,
    schema,
    loadingSearch,
    loadingDownload,
    createFilter,
    columnSets,
    allColumns,
    viewMode,
    setViewMode,
    getMetadataIdsPage,
  } = useAdvancedSearchContext();

  const canBulkUpdate = useMemo(() => {
    return (
      !loading &&
      !loadingSearch &&
      !loadingDownload &&
      pagination.total > 0 &&
      // We don't have bulkUpdate on manifestations yet
      viewMode === Concept.work
    );
  }, [loading, loadingDownload, loadingSearch, pagination.total, viewMode]);

  const startBulkUpdate = useCreateBulkUpdate(
    viewMode,
    pagination.total,
    getMetadataIdsPage,
  );

  /**
   * Dynamically load unknown codelists.
   */
  const allExistingCodelistIds = useAllCodeListIds();
  const codelistIds = useMemo(() => {
    const dynamic = availableFilters.map(a => a.codelistId) as CodeListId[];
    return unique([...allExistingCodelistIds, ...dynamic].filter(c => c));
  }, [allExistingCodelistIds, availableFilters]);

  const {CreateFilterDialog, openCreateFilterDialog} =
    useCreateFilterDialog(createFilter);

  const {DownloadCsvDialog, openDownloadCsvDialog} =
    useDownloadDialog(onDownload);

  const handleAddCurrentFilter = useCallback(() => {
    addCurrentFilter(findNextFilter(currentFilters, availableFilters));
  }, [addCurrentFilter, availableFilters, currentFilters]);

  useEffect(() => {
    if (currentFilters.filter(isNotCustomFilter).length === 0) {
      handleAddCurrentFilter();
    }
  }, [currentFilters, handleAddCurrentFilter]);

  const tableConfig = useMemo(() => {
    return {
      ...advancedSearchTableConfig,
      pinnedColumns: {
        left: viewMode === Concept.work ? ['workId'] : ['ean'],
      },
    };
  }, [viewMode]);

  if (loading || !codelistIds) {
    return (
      <Card>
        <CircularProgress />
      </Card>
    );
  }

  return (
    <CodeListsGate codeLists={codelistIds}>
      <ThesaurusesGate thesauruses={['bokbasen', 'genreandform', 'thema']}>
        <Card>
          <Grid container flex={1} spacing={2} rowSpacing={2}>
            <Grid item xs={6} sm={3}>
              <FilterSets />
            </Grid>
            <Grid item xs={6} sm={9}>
              <Layout flex={1} horizontal pb={1}>
                <Grid
                  container
                  justifyContent={'space-between'}
                  alignItems={'center'}>
                  <Grid item xs={1}>
                    <HelpTooltip />
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    display={'flex'}
                    justifyContent={'flex-end'}>
                    <CustomFilter
                      label={t('data.filter.custom.international.label')}
                      filter={excludeInternationalFilter}
                    />
                    <CustomFilter
                      label={t('data.filter.custom.bokbasen.label')}
                      filter={excludeBokbasenFilter}
                    />
                  </Grid>
                </Grid>
              </Layout>
              {currentFilters.map(f => (
                <Filter key={f.id} filter={f} />
              ))}

              <Layout flex={1} horizontal pb={1}>
                <Grid container>
                  <Grid item sm={8} pr={1}>
                    <Layout alignItems={'flex-start'}>
                      <IconButton
                        postText={
                          <Text variant={'button'}>
                            {t('page.search.filter.add')}
                          </Text>
                        }
                        size={'small'}
                        icon={'Add'}
                        onClick={handleAddCurrentFilter}
                      />
                      <IconButton
                        postText={
                          <Text variant={'button'}>
                            {t('page.search.filter.save')}
                          </Text>
                        }
                        size={'small'}
                        icon={'StarSmall'}
                        onClick={openCreateFilterDialog}
                      />
                      {CreateFilterDialog}
                    </Layout>
                  </Grid>
                  <Grid item sm={3}>
                    <Layout horizontal justifyContent={'flex-end'}>
                      <IconButton
                        postText={
                          <Text
                            kind={loadingDownload ? 'disabled' : 'primary'}
                            variant={'button'}>
                            {t('page.search.filter.download.title')}
                          </Text>
                        }
                        icon={'Download'}
                        disabled={loadingDownload}
                        onClick={() =>
                          openDownloadCsvDialog(columnSets, allColumns)
                        }
                      />
                      {DownloadCsvDialog}
                      <Spacer width={3} />
                      <ActionButton
                        icon={'Search'}
                        title={t('general.search')}
                        onClick={() => onSearch(true)}
                        disabled={loadingSearch}
                        showSpinnerOnClick={true}
                      />
                    </Layout>
                  </Grid>
                </Grid>
              </Layout>
            </Grid>
          </Grid>
        </Card>

        <Spacer height={2} />

        <SingleColumnSelectionAccordion />

        <Table
          loading={loadingSearch}
          schema={schema}
          configuration={tableConfig}
          data={searchResult}
          pagination={pagination}
          header={
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              width={'100%'}>
              <Box>
                <Text variant={'body1'}>
                  {t('page.search.hits', {count: pagination.total})}
                </Text>
              </Box>
              {isFeatureEnabled('bulkUpdate') ||
              isFeatureEnabled('workViewMode') ? (
                <Box display={'flex'} flexDirection={'row'}>
                  {isFeatureEnabled('workViewMode') ? (
                    <SelectViewMode
                      viewMode={viewMode}
                      onChange={setViewMode}
                    />
                  ) : null}

                  {isFeatureEnabled('bulkUpdate') ? (
                    <ActionButton
                      icon={'Edit'}
                      size={'medium'}
                      background={'transparent'}
                      disabled={!canBulkUpdate}
                      onClick={startBulkUpdate}
                      showSpinnerOnClick
                      title={'Masseoppdater'}
                    />
                  ) : null}
                </Box>
              ) : null}
            </Box>
          }
        />
      </ThesaurusesGate>
    </CodeListsGate>
  );
};
