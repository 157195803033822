import assert from 'assert-ts';
import {Concept} from '../types';
import {
  BulkUpdate,
  BulkUpdateCreate,
  BulkUpdateCreateResponse,
  BulkUpdateList,
  GetTokens,
} from './types';
import {Schemas} from './dto.generated';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpGet, httpPost} from './http/ebba';
import {
  mapBulkUpdateDto,
  mapBulkUpdateSummaryDto,
  mapToBulkUpdateWorkRequest,
} from './mappers';

export const getBulkUpdate = (
  id: string,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<BulkUpdate> => {
  return exceptionToPromiseReject(() =>
    // Use BulkUpdateDto from Schemas when API is ready.
    httpGet<Schemas.BulkUpdateStatusResponse>({
      subdir: 'bulkupdate/{id}',
      subdirParams: {id},
      mock: !!mock,
      getTokens,
    }).then(r => mapBulkUpdateDto(id, r)),
  );
};

export const getBulkUpdates = (
  getTokens: GetTokens,
  page: number,
  size: number,
  mock?: boolean,
): Promise<BulkUpdateList> => {
  return exceptionToPromiseReject(() =>
    httpGet<Schemas.AllBulkUpdatesPageResponse>({
      subdir: 'bulkupdate',
      queryParams: {
        page: page - 1,
        size,
        sort: 'created,desc',
      },
      mock: !!mock,
      getTokens,
    }).then(response => ({
      pages: response.totalPages ?? 0,
      elements: response.totalElements ?? 0,
      items: (response.items ?? []).map(mapBulkUpdateSummaryDto),
    })),
  );
};

export const postBulkUpdate = (
  bulkUpdate: BulkUpdateCreate,
  ids: string[],
  getTokens: GetTokens,
  mock?: boolean,
): Promise<BulkUpdateCreateResponse> => {
  return exceptionToPromiseReject(() => {
    if (bulkUpdate.type === Concept.work) {
      return httpPost<BulkUpdateCreateResponse, Schemas.BulkUpdateWorkRequest>({
        subdir: 'bulkupdate/work',
        body: mapToBulkUpdateWorkRequest(bulkUpdate, ids),
        mock: !!mock,

        getTokens,
      });
    }

    assert(false, 'postBulkUpdate: only type work implemented', bulkUpdate);
  });
};
