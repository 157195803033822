import {useMemo} from 'react';
import {Schema} from 'schemaDefinition/types';
import {getPartsFlattended} from 'schemaDefinition/functions';
import {PartValueRenderers} from '../types';

export const useTableSchema = (
  schema: Schema,
  partValueRenderers?: PartValueRenderers,
): Schema => {
  return useMemo(() => {
    const flatParts = getPartsFlattended(schema.parts);

    const filteredSchema = {
      ...schema,
      parts: flatParts.filter(
        p => p.type !== 'schema' || partValueRenderers?.[p.name],
      ),
    };

    return filteredSchema;
  }, [partValueRenderers, schema]);
};
