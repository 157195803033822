import {ViewMode} from '../types';
import {Schemas} from '../dto.generated';

export const mapViewMode = (
  viewmode?: ViewMode,
): Schemas.ExportRequest['type'] => {
  if (viewmode === 'work') {
    return 'WORK';
  }

  // Api also defaults to MANIFESTATION
  return 'MANIFESTATION';
};
