import {PartValueRenderer} from 'schema/types';
import {useLocalization} from 'localization';
import {useTextValue} from 'schema/preview/hooks';

export const DateTime: PartValueRenderer = ({value}) => {
  const TextValue = useTextValue();
  const {formatDate} = useLocalization();

  if (!Array.isArray(value)) {
    return <TextValue>{formatDate(value as string, 'short', true)}</TextValue>;
  }

  return (
    <>
      {value.map((v, i) => {
        return (
          <TextValue key={i}>
            {formatDate(v as string, 'short', true)}
          </TextValue>
        );
      })}
    </>
  );
};
