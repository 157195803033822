import {useCallback} from 'react';
import {Concept} from 'types';
import {Column, Filter, ViewMode} from 'api/types';
import {mapDataToMetadataId} from '../mappers';
import {useSearcher} from './useSearcher';

export const useGetMetadataIds = (allColumns: Column[]) => {
  const {search} = useSearcher(allColumns);

  return useCallback(
    (
      viewMode: ViewMode,
      filters: Filter[],
      sort?: string,
      page: number = 0,
      size: number = 200,
    ) => {
      return search(
        viewMode,
        filters,
        viewMode === Concept.work ? ['workId'] : ['ean'],
        page,
        size,
        sort,
      ).then(r => {
        return r.rows.map(mapDataToMetadataId);
      });
    },
    [search],
  );
};
