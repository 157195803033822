import React, {useCallback, useMemo} from 'react';
import assert from 'assert-ts';
import {BulkUpdateCreate} from 'api/types';
import {Data} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {useBulkUpdateItems} from 'services/bulkUpdateItems';
import {useAllCodeListIds, useCodelists} from 'services/codeLists';
import {Layout, Spacer, Text, Wizard} from 'components';
import {DataFormV1} from 'schema/form';
import {validate} from 'schema/form/functions/validators';
import {StepId} from '../types';
import {BulkUpdateInProgress, BulkUpdatePreview} from '../../../widgets';
import {useWizardStepLabels} from '../hooks';
import {
  NewBulkUpdateWizardProps,
  useNewBulkUpdateWizardState,
} from '../hooks/useNewBulkUpdateWizardState';

const empty: Data = {};

// const fieldAndOperationSchema: TypedSchema<BulkUpdateCreate> = {
//   name: 'fieldAndOperationSchema',
//   parts: [
//     {
//       type: 'codelist',
//       name: 'bulkUpdateField',
//       codelistId: 'bulkUpdateWorkField',
//       required: true,
//       cardinality: 'single',
//     },
//     {
//       type: 'codelist',
//       name: 'bulkUpdateAction',
//       codelistId: 'bulkUpdateAction',
//       required: true,
//       cardinality: 'single',
//     },
//   ],
// };

export const NewBulkUpdateWizard: React.FC<
  NewBulkUpdateWizardProps
> = props => {
  const {
    stepIds,
    currentStep,
    bulkUpdateState,
    bulkUpdateSchema: schema,
    showValidationErrors,
    onBulkUpdateStateEdited,
    gotoStep,
    onSave,
  } = useNewBulkUpdateWizardState(props);

  const {formatDate} = useLocalization();
  const bulkUpdateItems = useBulkUpdateItems();

  const codelistIds = useAllCodeListIds();
  const codeListMap = useCodelists(codelistIds);

  const stepLabels = useWizardStepLabels();

  const step1Content = useMemo(() => {
    return (
      <Layout>
        <BulkUpdateInProgress />
        <DataFormV1
          id={'STEP_1_EnterValues'}
          schema={schema}
          data={bulkUpdateState}
          mode="edit"
          relatedData={empty}
          showErrors={showValidationErrors}
          onDataChanged={onBulkUpdateStateEdited}
        />
      </Layout>
    );
  }, [bulkUpdateState, onBulkUpdateStateEdited, schema, showValidationErrors]);

  const step2Content = useMemo(
    () => (
      <BulkUpdatePreview
        bulkUpdate={{
          // Will have all required values in this step after validation
          ...(bulkUpdateState as BulkUpdateCreate),
          items: {
            unprocessed: bulkUpdateItems.itemIds,
            failed: [],
            succeeded: [],
          },
          id: 'new',
          status: 'NEW',
          created: formatDate(new Date().valueOf()),
        }}
      />
    ),
    [formatDate, bulkUpdateItems.itemIds, bulkUpdateState],
  );

  const validateStep = useCallback(
    (stepIndex: number): boolean => {
      switch (stepIndex) {
        case 0:
          return (
            validate(bulkUpdateState, undefined, schema, codeListMap).valid ===
            'valid'
          );
        case 1:
          // Read-only step, always valid
          return true;
        default:
          assert(false, 'Invalid step index', {stepIndex});
      }
    },
    [codeListMap, bulkUpdateState, schema],
  );

  if (bulkUpdateItems.itemIds.length === 0) {
    return (
      <Layout>
        <Spacer height={4} />
        <Text kind="error" variant="h3">
          Fant ingenting å behandle!
        </Text>
      </Layout>
    );
  }

  return (
    <Wizard
      initialStepId={currentStep}
      title={''}
      showSaveCancel={false}
      showPrev={false}
      validateStep={validateStep}
      onCancel={() => {}}
      onSave={onSave}
      onGotoStep={step => gotoStep(step as StepId)}
      steps={[
        {
          id: stepIds[0],
          label: stepLabels[0],
          content: step1Content,
        },
        {
          id: stepIds[1],
          label: stepLabels[1],
          content: step2Content,
        },
      ]}
    />
  );
};
