export async function getResourcesBatched<TItem>(
  total: number,
  size: number,
  getBatch: (page: number, size: number) => Promise<TItem[]>,
) {
  const batches = Math.ceil(total / size);

  const results: TItem[] = [];
  for (let i = 1; i <= batches; i++) {
    const batch = await getBatch(i, size);
    results.push(...batch);
  }

  return results;
}
