import {
  AgentSubType,
  CollectionSubType,
  Concept,
  DerivedConcept,
  EntityType,
} from 'types';
import {CodeListRef, ManifestationStatus} from 'api/types';
import {ValidationResult} from 'schema/form/functions/validators/types';
import {
  Data,
  SchemaModifierMap,
  TypedSchemaModifierMap,
  Valid,
} from 'schemaDefinition/types';

export type SchemaReference =
  | {
      entity: Concept.agent;
      subType: AgentSubType;
    }
  | {
      entity: Concept.collection;
      subType: CollectionSubType;
    }
  | Concept.work
  | Concept.expression
  | Concept.manifestation
  | DerivedConcept.workSummary
  | Concept.bulkUpdate;

export type SchemaMode =
  | 'full'
  /**
   * Will limit schema, i.e. parts requiring right-panel edit be read-only:
   * - Thesaurus
   * - LinkedAgent
   * - LinkedLiterary
   */
  | 'dialog';

export const schemaRefPerson: SchemaReference = {
  entity: Concept.agent,
  subType: Concept.person,
};
export const schemaRefCorporation: SchemaReference = {
  entity: Concept.agent,
  subType: Concept.corporation,
};
export const schemaRefPublisher: SchemaReference = {
  entity: Concept.agent,
  subType: Concept.publisher,
};
export const schemaRefEvent: SchemaReference = {
  entity: Concept.agent,
  subType: Concept.event,
};
export const schemaRefSeries: SchemaReference = {
  entity: Concept.collection,
  subType: Concept.series,
};
export const schemaRefEducationalSeries: SchemaReference = {
  entity: Concept.collection,
  subType: Concept.educationalSeries,
};
export const schemaRefOtherConnection: SchemaReference = {
  entity: Concept.collection,
  subType: Concept.otherConnection,
};
export const schemaRefPublisherSeries: SchemaReference = {
  entity: Concept.collection,
  subType: Concept.publisherSeries,
};
export const schemaRefWork: SchemaReference = Concept.work;
export const schemaRefexpression: SchemaReference = Concept.expression;
export const schemaRefmanifestation: SchemaReference = Concept.manifestation;

export const schemaRefWorkSummary: SchemaReference = DerivedConcept.workSummary;

/** SchemaReference for schemas that may be modified */
export type ModifiableSchemaReference = Extract<
  SchemaReference,
  Concept.work | Concept.expression | Concept.manifestation
>;

export type WorkType =
  | (typeof CodeListRef)['WORK_TYPE']['Bok']
  | (typeof CodeListRef)['WORK_TYPE']['Vare'];

export const WorkBook: WorkType = CodeListRef.WORK_TYPE.Bok;
export const WorkMerchandise: WorkType = CodeListRef.WORK_TYPE.Vare;

export enum StatusModifierKey {
  new = 'NEW',
  precat = 'PRE_CATALOGED',
  cat = 'CONTROL',
  done = 'COMPLETED',
}

export type MetadataModifierKeyGeneric = `${WorkType}.${StatusModifierKey}`;

// Use string literals instead of template strings for intellisense/typing to work
export type MetadataModifierKey =
  | 'BOOK.NEW'
  | 'BOOK.PRE_CATALOGED'
  | 'BOOK.CONTROL'
  | 'BOOK.COMPLETED'
  | 'MERCHANDISE.NEW'
  | 'MERCHANDISE.PRE_CATALOGED'
  | 'MERCHANDISE.CONTROL'
  | 'MERCHANDISE.COMPLETED';

// Using template strings here to verify that
// the work and modifier types match MetadataModifierKey type
export const MetadataModifierKeys: Record<WorkType, MetadataModifierKey[]> = {
  BOOK: [
    `${WorkBook}.${StatusModifierKey.new}`,
    `${WorkBook}.${StatusModifierKey.precat}`,
    `${WorkBook}.${StatusModifierKey.cat}`,
    `${WorkBook}.${StatusModifierKey.done}`,
  ],
  MERCHANDISE: [
    `${WorkMerchandise}.${StatusModifierKey.new}`,
    `${WorkMerchandise}.${StatusModifierKey.precat}`,
    `${WorkMerchandise}.${StatusModifierKey.cat}`,
    `${WorkMerchandise}.${StatusModifierKey.done}`,
  ],
};

export type MetadataModifierMap = SchemaModifierMap<MetadataModifierKey>;

export type TypedMetadataModifierMap<T extends Data> = TypedSchemaModifierMap<
  T,
  MetadataModifierKey
>;

export type MetadataValidationResultV0 = {
  valid: Valid;
  work?: ValidationResult;
  expression?: ValidationResult;
  manifestation?: ValidationResult;
};

export type ManifestationValidationResult = {
  manifestationId: string;
  manifestationStatus: ManifestationStatus;
  validation: ValidationResult;
};

export type ExpressionValidationResult = {
  valid: Valid;
  expressionId: string;
  expressionStatus: ManifestationStatus;
  validation: ValidationResult;
  manifestations: ManifestationValidationResult[];
};

export type MetadataValidationResult = {
  valid: Valid;
  work?: ValidationResult;
  workId?: string;
  workStatus?: ManifestationStatus;
  expressions?: ExpressionValidationResult[];
};

export type ManifestationValidationResultV0 = MetadataValidationResultV0;

export type ExpressionValidationResultV0 = Omit<
  MetadataValidationResultV0,
  Concept.manifestation
>;

export type WorkValidationResult = Omit<
  MetadataValidationResultV0,
  Concept.expression | Concept.manifestation
>;

export type MainSchemaName = EntityType | AgentSubType;

export type SubSchemaName = 'dewey' | 'productOwners';

export type SchemaName = MainSchemaName | SubSchemaName;
