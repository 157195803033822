import {AgentSubType} from 'types';
import {CodeListId, ThesaurusId, ThesaurusValue} from 'api/types';
import {Condition} from './conditions';
import {SubEntity} from './genericTypes';
import {LinkedRoleCodeListId, RoleEntityCode} from './linkTypes';
import {Cardinality, FieldRequired, FieldRequiredSimple} from './required';
import {AnyValidation, ValidationErrorLevel} from './validation';
import {
  Data,
  DataValue,
  LinkedAgentMultiRole,
  NameVariantValue,
  YearOrDateValue,
} from './values';

/** For now, applicable to GREP-codes */
export type SelectableThesaurusNodeType =
  | 'education_program_type'
  | 'education_program'
  | 'program_area'
  | 'subject_code';

export type PartAction = 'copy';

export type PartCompare =
  /** Compare full value, may be array, object, simple value */
  | 'fullValue'
  /** Compare corresponding leaf-values in array/object structure, or until fullValue or items is specified */
  | 'subValues'
  /** Compare corresponding items in a list. Applicable to parts with cardinality > 1 */
  | 'items';

export const DefaultPartCompare: PartCompare = 'fullValue';

export type BasePart<
  T,
  TEntity = Data,
  TLocalEntity = TEntity,
  TRelated = Data,
  TName extends keyof TLocalEntity = keyof TLocalEntity,
  TValue = TLocalEntity[TName],
> = {
  /** name will correspond to data value in corresponding context */
  name: TName;
  role?: undefined;
  labelKey?: string;
  /** Customization key, Key of modifier function to intercept updates to value, e.g. to format value */
  customizeKey?: string;
  readonly?: boolean;
  required?: FieldRequired<TEntity, TLocalEntity, TValue, TRelated>;
  cardinality?: Cardinality;
  /** Default value of part, if any */
  default?: T extends undefined ? T : T | T[];
  /**
   * TODO: Replace specifc validation props with this one
   * Validation rules for part.
   * When cardinality is multiple:
   * - validation will be applied to each value in array
   */
  validation?:
    | AnyValidation<TEntity, TLocalEntity, TValue, TRelated>
    | AnyValidation<TEntity, TLocalEntity, TValue, TRelated>[];
  /** Only applies to cardinality multiple/2. Will validate across list of values, e.g. to verify reader of audio book */
  listValidation?:
    | AnyValidation<TEntity, TLocalEntity, TValue, TRelated>
    | AnyValidation<TEntity, TLocalEntity, TValue, TRelated>[];
  /** Actions on part/part value, i.e. copy */
  actions?: PartAction[];
  /**
   * Compare mode for part (and possible subparts)
   * - fullValue: compare whole value (i.e. same json)
   * - subValues: Will compare the original value with the new value and show the difference
   *   and, vice versa, compare the new value with the original value and show the difference
   * - items: Will compare each item in array separately. Pr item, compare fullValue.
   *   Only applicable for parts with cardinality multiple or 2
   *
   * Default is 'fullValue'
   *
   * Only applicable for parts whose parent is a schema with compare set to 'nested'
   */
  compare?: PartCompare;
};

/**
 * Defines same property keys as BasePart, but all as optional and undefined
 * Purpose is to ease use of general Part type, when all subtypes of Part
 * have these property keys
 */
type NoBasePart = {
  name?: undefined;
  /** role is used to refer to part, but will not correspond to data value, as BasePart.name does */
  role: string;
  readonly?: undefined;
  required?: undefined;
  cardinality?: undefined;
  labelKey?: undefined;
  /** Key of modifier function to intercept updates to value, e.g. to format value */
  customizeKey?: undefined;
  default?: undefined;
  validation?: undefined;
  listValidation?: undefined;
  actions?: undefined;
  compare?: undefined;
};

/**
 * Expands schema with conditional parts. Defines one or more cases. First case with conditions
 * satisfied will be expanded. A default may also be defined, which will be expanded
 * when none of the cases are satisfied.
 */
export type PartExpand<
  TEntity = Data,
  TLocalEntity = TEntity,
  TRelated = Data,
> = Omit<NoBasePart, 'default'> & {
  type: 'expand';
  when: Array<{
    /** When array of conditions, all conditions must be true (and) */
    condition:
      | Condition<TEntity, TLocalEntity, DataValue, TRelated>
      | Condition<TEntity, TLocalEntity, DataValue, TRelated>[];
    parts: SeparatedParts<TEntity, TLocalEntity, TRelated>;
  }>;
  default?: SeparatedParts<TEntity, TLocalEntity, TRelated>;
};

export type PartSchema<
  TEntity = Data,
  TLocalEntity = TEntity,
  TRelated = Data,
  TName extends keyof TLocalEntity = keyof TLocalEntity,
> = (TLocalEntity[TName] extends Data
  ? Omit<
      BasePart<Data, TEntity, SubEntity<TLocalEntity, TName>, TRelated>,
      'compare'
    > &
      Schema<TEntity, SubEntity<TLocalEntity, TName>, TRelated>
  : Omit<BasePart<Data, TEntity, Data, TRelated>, 'compare'> &
      Schema<TEntity, Data, TRelated>) & {
  type: 'schema';
  variant?: 'form' | 'table';
  /**
   * When noValidation is set, validation will be ignored for its subparts
   */
  noValidation?: boolean;
  /**
   * Whether to compare schema part nested or fullValue
   * - nested: compare values of nested parts, i.e. parts in nested schemas
   * - fullValue: compare whole value (i.e. same json)
   * - subValues: Will compare the original value with the new value and show the difference
   *   and, vice versa, compare the new value with the original value and show the difference
   * - items: Will compare each item in array separately. Pr item, compare fullValue.
   *   Only applicable for parts with cardinality multiple or 2
   *
   * Default is 'fullValue'
   */
  compare?: 'nested' | PartCompare;
};

export type PartText<
  TEntity = Data,
  TLocalEntity = TEntity,
  TRelated = Data,
> = BasePart<string, TEntity, TLocalEntity, TRelated> & {
  type: 'text';
  /** Prefix string/icon reference displayed in input field and in preview value */
  prefix?: string;
  /** Prefix override when value is empty: string/icon reference displayed in input field and in preview value */
  prefixOnEmpty?: string;
  /** Suffix string/icon reference displayed in input field and in preview value */
  suffix?: string;
  /** Suffix override when value is empty: string/icon reference displayed in input field and in preview value */
  suffixOnEmpty?: string;
};

export type PartTextArea<
  TEntity = Data,
  TLocalEntity = TEntity,
  TRelated = Data,
> = BasePart<string, TEntity, TLocalEntity, TRelated> & {
  type: 'textarea';
  maxLength?: number;
};

// Html part, only read-only for now, i.e. omit props for edit
export type PartHtml<
  TEntity = Data,
  TLocalEntity = TEntity,
  TRelated = Data,
> = Omit<
  BasePart<string, TEntity, TLocalEntity, TRelated>,
  'readonly' | 'required' | 'customizeKey' | 'default' | 'validation'
> &
  Pick<NoBasePart, 'required' | 'customizeKey' | 'default' | 'validation'> & {
    type: 'html';
    readonly: true;
  };

export type PartInt<
  TEntity = Data,
  TLocalEntity = TEntity,
  TRelated = Data,
> = BasePart<number, TEntity, TLocalEntity, TRelated> & {
  type: 'int';
  /** Prefix string/icon reference (icon:name) displayed in input field and in preview value */
  prefix?: string;
  /** Prefix override when value is empty: string/icon reference displayed in input field and in preview value */
  prefixOnEmpty?: string;
  /** Suffix string/icon reference displayed in input field and in preview value */
  suffix?: string;
  /** Suffix override when value is empty: string/icon reference displayed in input field and in preview value */
  suffixOnEmpty?: string;
};

export type PartBool<
  TEntity = Data,
  TLocalEntity = TEntity,
  TRelated = Data,
> = BasePart<boolean, TEntity, TLocalEntity, TRelated> & {
  type: 'bool';
};

export type PartYear<
  TEntity = Data,
  TLocalEntity = TEntity,
  TRelated = Data,
> = BasePart<number, TEntity, TLocalEntity, TRelated> & {
  type: 'year';
  default?: number;
};

export type PartDate<
  TEntity = Data,
  TLocalEntity = TEntity,
  TRelated = Data,
> = BasePart<string, TEntity, TLocalEntity, TRelated> & {
  type: 'date';
};

export type PartYearOrDate<
  TEntity = Data,
  TLocalEntity = TEntity,
  TRelated = Data,
> = BasePart<YearOrDateValue, TEntity, TLocalEntity, TRelated> & {
  type: 'yearOrDate';
  default?: number | string;
  /** Add checkbox to indicate BCE. Default false */
  includeBce?: boolean;
  /** Add checkbox to indicate approximate year/date. Default false */
  includeApproximate?: boolean;
};

export type PartCodeList<
  TEntity = Data,
  TLocalEntity = TEntity,
  TRelated = Data,
> = BasePart<string, TEntity, TLocalEntity, TRelated> & {
  type: 'codelist';
  codelistId: CodeListId;
  showCode?: boolean;
  /** Default variant is 'dropdown' */
  variant?: 'dropdown' | 'buttons';
};

export type ShowThesaurusCode = boolean | 'onlyCode';
export type PartThesaurus<
  TEntity = Data,
  TLocalEntity = TEntity,
  TRelated = Data,
> = Omit<
  BasePart<ThesaurusValue, TEntity, TLocalEntity, TRelated>,
  'cardinality' | 'compare'
> & {
  type: 'thesaurus';
  thesaurusId: ThesaurusId;
  // Must always be multiple
  cardinality: 'multiple';
  /**
   * Thesaurus compare is handled specifically
   */
  compare?: undefined;
  showCode?: ShowThesaurusCode;
  /**
   * If set,
   * - only nodes of these types will be selectable,
   * - only nodes of these types will show code
   * - thesaurus value will be ordered by order of types in array
   * If not set, all nodes will be selectable
   */
  selectableNodeTypes?: SelectableThesaurusNodeType[];
  /**
   * Thesaurus value display variants:
   * - byCategory: show terms grouped by top level node (facet). Default
   * - byType: show terms grouped by node type (assumes nodes have type)
   */
  variant?: 'byCategory' | 'byType';
};

export type PartLinkedAgent<
  TEntity = Data,
  TLocalEntity = TEntity,
  TRelated = Data,
  TName extends keyof TLocalEntity = keyof TLocalEntity,
> = BasePart<LinkedAgentMultiRole, TEntity, TLocalEntity, TRelated, TName> & {
  type: 'linkedAgent';
  /**
   * Valid entity subtypes for linked agent.
   * Used to limit "linkable" agents in linked agent search
   */
  entitySubtypes?: AgentSubType[];
  /**
   * If set, static role is assumed and linked value edit
   * will not include selector for roles of linked entity
   */
  entityRole?: string;
  roleCodelistId: CodeListId;
  nameRequired?: FieldRequired<
    TEntity,
    TLocalEntity,
    TLocalEntity[TName],
    TRelated
  >;
};

export type PartLinkedLiterary<
  TEntity = Data,
  TLocalEntity = TEntity,
  TRelated = Data,
> = Omit<
  BasePart<LinkedAgentMultiRole, TEntity, TLocalEntity, TRelated>,
  'compare'
> & {
  type: 'linkedLiterary';
  /** subValues not suported for linked literary */
  compare?: Exclude<PartCompare, 'subValues'>;
  /**
   * Id of linked role codelist containing possible link roles
   */
  roleCodelistId: LinkedRoleCodeListId;
  /**
   * If set, static role is assumed and linked value edit will not include selector for roles of linked entity
   * E.g. manifestation may only be part of publisher series, and vice versa
   */
  linkRole?: RoleEntityCode;
  /**
   * When link role has link property: whether link-prop is required
   */
  linkPropRequired?: FieldRequiredSimple | undefined;
};

export type PartNameVariant<
  TEntity = Data,
  TLocalEntity = TEntity,
  TRelated = Data,
> = Omit<
  BasePart<NameVariantValue, TEntity, TLocalEntity, TRelated>,
  'cardinality'
> & {
  type: 'nameVariant';
  agentType: AgentSubType;
  // When used, namevariants will always have cardinality multiple
  cardinality: 'multiple';
};

type SeparatorVariant = 'card' | 'line' | 'none';

export type PartSeparator = NoBasePart & {
  type: 'separator';
  titleKey?: string;
  /**
   * Form variants of separation/grouping. Default 'none'
   * - 'card': will separate each group into different cards
   * - 'line': will just add a horizontal ruler to separate groups
   * - 'none': no separator in form mode
   */
  form?: SeparatorVariant;
  /**
   * Preview variants of separation/grouping. Default 'none'
   * - 'card': will separate each group into different cards
   * - 'line': will just add a horizontal ruler to separate groups
   * - 'none': no separator in preview mode
   */
  preview?: SeparatorVariant;
  include?: 'always';

  name?: undefined;
};

export type Part<TEntity = Data, TLocalEntity = TEntity, TRelated = Data> =
  | PartText<TEntity, TLocalEntity, TRelated>
  | PartTextArea<TEntity, TLocalEntity, TRelated>
  | PartHtml<TEntity, TLocalEntity, TRelated>
  | PartInt<TEntity, TLocalEntity, TRelated>
  | PartBool<TEntity, TLocalEntity, TRelated>
  | PartYear<TEntity, TLocalEntity, TRelated>
  | PartDate<TEntity, TLocalEntity, TRelated>
  | PartYearOrDate<TEntity, TLocalEntity, TRelated>
  | PartCodeList<TEntity, TLocalEntity, TRelated>
  | PartThesaurus<TEntity, TLocalEntity, TRelated>
  | PartLinkedAgent<TEntity, TLocalEntity, TRelated>
  | PartLinkedLiterary<TEntity, TLocalEntity, TRelated>
  | PartNameVariant<TEntity, TLocalEntity, TRelated>
  | PartExpand<TEntity, TLocalEntity, TRelated>
  | PartSchema<TEntity, TLocalEntity, TRelated>;

export type PartType = Part<Data, Data>['type'];

export type PartRef = Partial<
  Pick<BasePart<unknown, Data, Data>, 'name' | 'labelKey'> & {
    type: PartType;
  }
>;

/**
 * A part row consists of a single part or a list of parts
 */
export type PartRow<TEntity = Data, TLocalEntity = TEntity, TRelated = Data> =
  | Part<TEntity, TLocalEntity, TRelated>
  | Part<TEntity, TLocalEntity, TRelated>[];

/**
 * Top level parts are layed out vertically, while second level parts (PartRow)
 * are layed ouut horizontally, e.g.:
 * [
 *   part1,
 *   [part2, part3],
 *   part4
 * ]
 * part1, [part2, part3] and part4 are layed out vertically, while
 * part2 and part3 are layed out horizontally.
 */
export type Parts<
  TEntity = Data,
  TLocalEntity = TEntity,
  TRelated = Data,
> = PartRow<TEntity, TLocalEntity, TRelated>[];

export type SeparatedPart<
  TEntity = Data,
  TLocalEntity = TEntity,
  TRelated = Data,
> = PartSeparator | PartRow<TEntity, TLocalEntity, TRelated>;

/**
 * @depricated Use SeparatedPart[]
 */
export type SeparatedParts<
  TEntity = Data,
  TLocalEntity = TEntity,
  TRelated = Data,
> = SeparatedPart<TEntity, TLocalEntity, TRelated>[];

export type GroupedParts<
  TEntity = Data,
  TLocalEntity = TEntity,
  TRelated = Data,
> = {
  titleKey?: string;
  role?: string;
  parts: SeparatedParts<TEntity, TLocalEntity, TRelated>;
};

// Use TypedSchema if you require typing.
export type Schema<TEntity = Data, TLocalEntity = TEntity, TRelated = Data> = {
  name: string;
  /** Used to lookup schema scoped localizations */
  key?: string;
  /**
   * Used when aggregating schemas, e.g. for change request, for global references
   * to be resolved correctly in context of aggregated schema and data
   *
   * @example
   * schema: {
   *   work: { globalScopePath: 'work', ...workSchema},
   *   expression: { globalScopePath: 'expression', ...expressionSchema},
   *   manifestation: { globalScopePath: 'manifestation', ...manifestationSchema},
   * }
   * data: {
   *  work: {...},
   *  expression: {...},
   *  manifestation: {...},
   * }
   * */
  globalScopePath?: string;
  parts: SeparatedParts<TEntity, TLocalEntity, TRelated>;
};

export type TypedSchema<TEntity, TRelated = Data> = Schema<
  TEntity,
  TEntity,
  TRelated
>;

export type PartName = Part['name'];
export const Separator = (
  form: SeparatorVariant,
  preview: SeparatorVariant,
  titleKey?: string,
): PartSeparator => ({
  type: 'separator',
  role: 'group',
  titleKey,
  form,
  preview,
});

export const AlwaysIncludedSeparator = (
  form: SeparatorVariant,
  preview: SeparatorVariant,
): PartSeparator => ({
  ...Separator(form, preview),
  include: 'always',
});

/****************************
 * Resolved schema
 ****************************/

export type PathKeys = (string | number)[];

export type DataFormSchema = Schema<Data, Data>;

/**
 * Function signatures
 */

export type SideEffect = {
  valuePath: string;
  value: DataValue;
};

export type PartValueModifier = (params: {
  valuePath: string;
  value: DataValue;
  isDefaultValue: boolean;
  validate?: boolean;
  part?: Part<Data, Data>;
  oldValue?: DataValue;
  entityValue?: Data;
}) => {
  value: DataValue;
  sideEffects?: SideEffect[];
};

// Validation result

export type Valid = 'valid' | 'warning' | 'error';

export type ValidationError = {
  value: DataValue | undefined;
  valuePath: string;
  part: Part<Data, Data> | Schema<Data, Data>;
  localScope: Data | undefined;
  validation:
    | Condition<Data, Data>
    | 'required'
    | 'invalid type'
    | 'invalid value'
    | 'missing codelist'
    | 'custom';
  messageKey?: string;
  level: ValidationErrorLevel;
};

export type ChangeRequestAction = 'replace' | 'addItem' | 'removeItem';
