import {TableConfiguration} from 'schema/table/types';
import {defaultTableConfig} from 'schema/table/configuration/defaultTableConfig';
import {MetadataLink} from '../../../scenes/advancedSearch/schemas/renderers/table/MetadataLink';
import {Isbn} from '../../renderers';
import {advancedSearchDefaultColumns} from './columns';

export const advancedSearchTableConfig: TableConfiguration = {
  ...defaultTableConfig,
  defaultColumns: advancedSearchDefaultColumns,
  hideColumnSelector: true,
  disableFiltering: true,
  partValueDelimiter: {
    generalNote: '; ',
    workGeneralNote: '; ',
  },
  partValueRenderers: {
    ean: MetadataLink,
    workId: MetadataLink,
    isbn: Isbn,
  },
  hideFooter: false,
  pinnedColumns: {
    left: ['ean'],
  },
};
