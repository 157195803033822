import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {BulkUpdateCreate} from 'api/types';
import {useLocalization} from 'localization';
import {
  useBulkUpdateItems,
  useClearBulkUpdateItems,
} from 'services/bulkUpdateItems';
import {useSaveNewBulkUpdate} from 'services/data';
import {Scene, useSnacks} from 'components';
import {useSceneExtensionsContext} from 'sceneExtensions';
import {BulkUpdateTitle, NewBulkUpdateWizard} from './components';
import {useBulkUpdateTitle} from './hooks';

export const BulkUpdateNewSceneCore: React.FC = () => {
  const {t} = useLocalization();
  const navigate = useNavigate();
  const bulkUpdateItems = useBulkUpdateItems();
  const clearBulkUpdateItems = useClearBulkUpdateItems();

  const title = useBulkUpdateTitle(
    bulkUpdateItems.type,
    bulkUpdateItems.itemIds.length,
  );
  const {currentExtension} = useSceneExtensionsContext();

  const saveBulkUpdate = useSaveNewBulkUpdate();
  const {successSnack, errorSnack} = useSnacks();

  const handleCancel = useCallback(() => {
    navigate('/bulkUpdate');
  }, [navigate]);

  const handleSubmit = useCallback(
    (bulkUpdate: Partial<BulkUpdateCreate>, ids: string[]) => {
      return saveBulkUpdate(bulkUpdate as BulkUpdateCreate, ids)
        .then(bulkUpdateId => {
          successSnack(t('page.bulkUpdate.new.submit.success'));
          // Allow snacks to show before navigating
          setTimeout(() => {
            navigate(`/bulkUpdate/details/${bulkUpdateId}`);
            // Clear submitted items from state.
            clearBulkUpdateItems();
          }, 500);
        })
        .catch(error => {
          errorSnack(t('page.bulkUpdate.new.submit.failed', error));
        });
    },
    [
      clearBulkUpdateItems,
      errorSnack,
      navigate,
      saveBulkUpdate,
      successSnack,
      t,
    ],
  );

  return (
    <Scene
      browserTitle={t('appbar.bulkUpdate')}
      rightPanelConfig={currentExtension?.panelConfig}>
      <Scene.Content loadStatus={'Loaded'}>
        <BulkUpdateTitle title={title} type={bulkUpdateItems.type} />

        <NewBulkUpdateWizard
          bulkUpdateType={bulkUpdateItems.type}
          itemIds={bulkUpdateItems.itemIds}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      </Scene.Content>
      {currentExtension ? (
        <Scene.RightPanel>
          {currentExtension.renderRightPanel()}
        </Scene.RightPanel>
      ) : null}
    </Scene>
  );
};
