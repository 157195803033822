import {PartValueRenderer} from 'schema/types';
import {useLocalization} from 'localization';
import {useTextValue} from 'schema/preview/hooks';
import {isBulkUpdateErrorKey} from '../../functions';

export const BulkUpdateErrorMessage: PartValueRenderer = ({value}) => {
  const {t} = useLocalization();
  const Text = useTextValue();

  if (isBulkUpdateErrorKey(value)) {
    return <Text>{t(`page.bulkUpdate.error.${value}`)}</Text>;
  }

  // Show the key in case it's a new key we haven't mapped yet.
  return <Text>{typeof value === 'string' ? value : ''}</Text>;
};
