import React, {useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {NoProps} from 'types';
import {BulkUpdateSummary} from 'api/types';
import {useLocalization} from 'localization';
import {useBulkUpdates} from 'services/data';
import {Scene} from 'components';
import {SceneTitle} from 'components/scene/SceneTitle';
import {Table} from 'schema';
import {bulkUpdateSummaryTableConfig} from 'schemas';
import {bulkUpdateSummarySchema} from 'schemas/schemas';
import {useMapThesaurusValues} from './hooks/useMapThesaurusValues';

export const BulkUpdateListSceneCore: React.FC<NoProps> = () => {
  const {t} = useLocalization();
  const navigate = useNavigate();
  const title = t('appbar.bulkUpdate');

  const {data, status, pagination} = useBulkUpdates();

  const handleOnClick = useCallback(
    (row: BulkUpdateSummary) => {
      navigate(`/bulkUpdate/details/${row.id}`);
    },
    [navigate],
  );

  const genreAndFormMapper = useMapThesaurusValues('genreandform');
  const subjectsMapper = useMapThesaurusValues('bokbasen');
  const themaMapper = useMapThesaurusValues('thema');
  const grepMapper = useMapThesaurusValues('grep');

  const readableValues = useMemo(() => {
    return data?.map(item => {
      if (item.bulkUpdateField === 'genreAndForm') {
        return {
          ...item,
          currentValue: genreAndFormMapper(item.currentValue),
          newValue: genreAndFormMapper(item.newValue),
        };
      }
      if (item.bulkUpdateField === 'subjects') {
        return {
          ...item,
          currentValue: subjectsMapper(item.currentValue),
          newValue: subjectsMapper(item.newValue),
        };
      }
      if (item.bulkUpdateField === 'themes') {
        return {
          ...item,
          currentValue: themaMapper(item.currentValue),
          newValue: themaMapper(item.newValue),
        };
      }
      if (item.bulkUpdateField === 'grep') {
        return {
          ...item,
          currentValue: grepMapper(item.currentValue),
          newValue: grepMapper(item.newValue),
        };
      }

      return item;
    });
  }, [data, genreAndFormMapper, grepMapper, subjectsMapper, themaMapper]);

  return (
    <Scene browserTitle={title}>
      <Scene.Header>
        <SceneTitle title={title} />
      </Scene.Header>
      <Scene.Content>
        <Table<BulkUpdateSummary>
          schema={bulkUpdateSummarySchema}
          configuration={bulkUpdateSummaryTableConfig}
          data={readableValues ?? []}
          pagination={pagination}
          header={title}
          loading={status === 'Loading'}
          onRowClick={handleOnClick}
        />
      </Scene.Content>
    </Scene>
  );
};
