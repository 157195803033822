import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import clone from 'lodash/clone';
import {Concept} from 'types';
import {
  CurrentFilterType,
  CustomFilterType,
  Filter,
  GeneratedFilterType,
} from 'api/types';
import {AppState} from 'store/types';
import {excludeBokbasenFilter, excludeInternationalFilter} from 'api/functions';
import {
  useAdvancedSearchItems,
  useClearAdvancedSearchItems,
} from 'services/advancedSearchItems';
import {useSetAdvancedSearchViewMode} from 'services/advancedSearchViewMode';
import {SettingsAction_SetAdvancedSearchCurrentFilters} from 'services/settings';
import {CurrentFiltersType} from '../types';
import {
  assertAsCustomFilterType,
  isNotCustomFilter,
} from '../functions/assertAsCustomFilterType';

const advancedSearchSelector = (state: AppState) =>
  state.settings.advancedSearch.currentFilters;
export const useCurrentFilters = (): CurrentFiltersType => {
  const dispatch = useDispatch();
  const currentFilters = useSelector(advancedSearchSelector);
  const advancedSearchItems = useAdvancedSearchItems();
  const clearAdvancedSearchItems = useClearAdvancedSearchItems();
  const setAdvancedSearchViewMode = useSetAdvancedSearchViewMode();

  const setCurrentFilters = useCallback(
    (newFilters: React.SetStateAction<CurrentFilterType[]>) => {
      const filters = !Array.isArray(newFilters)
        ? newFilters(currentFilters)
        : newFilters;

      const action: SettingsAction_SetAdvancedSearchCurrentFilters = {
        type: 'SET_ADVANCEDSEARCH_FILTERS',
        payload: filters,
      };
      dispatch(action);
    },
    [currentFilters, dispatch],
  );

  const handleAddFilter = useCallback(
    (filter: Filter | CustomFilterType) => {
      const customFilter = assertAsCustomFilterType(filter);

      const currentMax = Math.max(
        ...currentFilters.filter(isNotCustomFilter).map(o => o.id),
      );
      const nextId = Number.isFinite(currentMax) ? currentMax + 1 : 1;

      const id = customFilter ? customFilter.id : nextId;

      setCurrentFilters(prevState => [
        ...prevState,
        {
          ...filter,
          id,
        },
      ]);
    },
    [currentFilters, setCurrentFilters],
  );

  const handleUpdateFilter = useCallback(
    (id: number, filter: Filter) => {
      setCurrentFilters(prevState => {
        const i = prevState.findIndex(f => f.id === id);
        const nextState = clone(prevState);
        nextState[i] = {
          ...filter,
          id,
        };
        return nextState;
      });
    },
    [setCurrentFilters],
  );

  const handleRemoveById = useCallback(
    (id: number) => {
      setCurrentFilters(prevState => {
        const i = prevState.findIndex(f => f.id === id);
        const nextState = clone(prevState);
        nextState.splice(i, 1);
        return nextState;
      });
    },
    [setCurrentFilters],
  );

  const handleSetCurrentFilters = useCallback(
    (filters: Array<Filter | CustomFilterType>) => {
      const currentFilters: CurrentFilterType[] = [];
      filters.forEach((f, i) => {
        const customFilter = assertAsCustomFilterType(f);
        currentFilters.push({
          ...f,
          id: customFilter ? customFilter.id : i,
        });
      });
      setCurrentFilters(currentFilters);
    },
    [setCurrentFilters],
  );

  useEffect(() => {
    if (advancedSearchItems.itemIds.length && advancedSearchItems.type) {
      const advancedSearchItemsFilter: GeneratedFilterType = {
        id: 2001,
        operation: 'EQUAL',
        values: advancedSearchItems.itemIds,
        name:
          advancedSearchItems.type === Concept.manifestation
            ? 'manifestationId'
            : 'workId',
        generated: true,
      };

      const newFilters: CurrentFilterType[] = [
        advancedSearchItemsFilter,
        ...currentFilters
          // Remove hidden
          .filter(c => !c.hidden)
          .filter(c => !c.generated)
          // Reset values on non-advanced search items.
          .map(c => ({
            ...c,
            values: [],
          })),
      ];
      setAdvancedSearchViewMode(advancedSearchItems.type);
      clearAdvancedSearchItems();
      setCurrentFilters(newFilters);
    } else {
      const invalidIds = currentFilters.some(
        c =>
          !Number.isInteger(c.id) ||
          c.id < 0 ||
          !Number.isFinite(c.id) ||
          Number.isNaN(c.id),
      );
      const hasDuplicates = currentFilters.some(c => {
        return currentFilters.filter(cc => cc.id === c.id).length > 1;
      });

      // Reset if any lines are invalid.
      if (invalidIds || hasDuplicates) {
        setCurrentFilters([excludeInternationalFilter, excludeBokbasenFilter]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(() => {
    return {
      currentFilters,
      addCurrentFilter: handleAddFilter,
      setCurrentFilters: handleSetCurrentFilters,
      updateCurrentFilter: handleUpdateFilter,
      removeCurrentFilterById: handleRemoveById,
    };
  }, [
    currentFilters,
    handleAddFilter,
    handleRemoveById,
    handleSetCurrentFilters,
    handleUpdateFilter,
  ]);
};
