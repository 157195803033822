import {Schemas, SearchSchemas} from '../dto.generated';

export const mapSeriesDocumentToSeriesWithRelationsDto = (
  dto: SearchSchemas.SeriesDocument,
): Schemas.SeriesWithRelationsDto => {
  return {
    id: dto.id ?? '',
    titles: (dto.titles ?? []).map(t => ({
      value: t.title ?? '<missing>',
      language: t.language ?? '<missing>',
    })),
    seriesType:
      (dto.type as Schemas.SeriesWithRelationsDto['seriesType']) ?? 'SERIES',
    relations: dto?.relations?.map(r => ({
      entityId: r.id,
      role: 'PART_OF',
      type: 'WORK',
      title: r.title,
    })),
    note: undefined,
  };
};
