import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import {assert} from 'assert-ts';
import {AdvancedSearchContextType} from '../types';
import {
  useAvailableFiltersWithLabel,
  useColumnSets,
  useCurrentColumns,
  useCurrentFilters,
  useDataFetch,
  useFilterSets,
  useGetMetadataIds,
  useSort,
  useViewMode,
} from '../hooks';
import {mapDataToMetadataId} from '../mappers';

export const AdvancedSearchContext = createContext<
  AdvancedSearchContextType | undefined
>(undefined);

export const useAdvancedSearchContextProviderValue =
  (): AdvancedSearchContextType => {
    const {statusFilterSets, filterSets, createFilterSet, deleteFilterSet} =
      useFilterSets();

    const {
      columnSets,
      createColumnSet,
      deleteColumnSet,
      statusColumnSets,
      editColumnSet,
    } = useColumnSets();

    const {statusAvailableFilters, availableFilters} =
      useAvailableFiltersWithLabel();

    const {
      currentFilters,
      addCurrentFilter,
      setCurrentFilters,
      updateCurrentFilter,
      removeCurrentFilterById,
    } = useCurrentFilters();

    const {
      loadingColumns,
      allColumns,
      currentColumns,
      schema,
      setCurrentColumns,
      addCurrentColumn,
      removeCurrentColumn,
    } = useCurrentColumns();

    const {
      onSearch,
      onDownload,
      searchResult,
      loadingSearch,
      loadingDownload,
      pagination,
    } = useDataFetch(allColumns);

    const {viewMode, setViewMode} = useViewMode({
      currentColumns,
      addCurrentColumn,
      removeCurrentColumn,
    });

    const getMetadataIds = useGetMetadataIds(allColumns);

    const {sort} = useSort();

    const {page, size} = pagination;

    const loading = useMemo(() => {
      return (
        loadingColumns ||
        [statusColumnSets, statusFilterSets, statusAvailableFilters].some(
          s => s === 'Loading',
        )
      );
    }, [
      loadingColumns,
      statusAvailableFilters,
      statusColumnSets,
      statusFilterSets,
    ]);

    const handleOnDownload = useCallback(
      (columns: string[] | undefined, sort?: string) => {
        return onDownload(viewMode, currentFilters, columns, sort);
      },
      [viewMode, currentFilters, onDownload],
    );

    const handleCreateFilterSet = useCallback(
      (name: string, isPublic: boolean) => {
        return createFilterSet(name, currentFilters, isPublic);
      },
      [createFilterSet, currentFilters],
    );

    const handleOnSearch = useCallback(
      (resetPage?: boolean) => {
        onSearch(
          viewMode,
          currentFilters,
          currentColumns,
          resetPage ? 1 : page,
          size,
          sort,
        );
      },
      [currentColumns, currentFilters, onSearch, page, viewMode, size, sort],
    );

    const handleGetMetadataIdsPage = useCallback(
      (page: number, size: number) => {
        return getMetadataIds(viewMode, currentFilters, sort, page, size);
      },
      [getMetadataIds, viewMode, currentFilters, sort],
    );

    const handleGetCurrentMetadataIds = useCallback(() => {
      return searchResult.map(mapDataToMetadataId);
    }, [searchResult]);

    useEffect(() => {
      onSearch(viewMode, currentFilters, currentColumns, page, size, sort);
      // Don't search on every filter change
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSearch, viewMode, currentColumns, page, size, sort]);

    return useMemo(
      () => ({
        loading,
        availableFilters,
        currentFilters,
        addCurrentFilter,
        filterSets,
        setCurrentFilters,
        updateCurrentFilter,
        removeCurrentFilterById,
        onSearch: handleOnSearch,
        onDownload: handleOnDownload,
        searchResult,
        pagination,
        allColumns: allColumns,
        currentColumns,
        loadingSearch,
        loadingDownload,
        createFilter: handleCreateFilterSet,
        deleteFilterSet,
        schema,
        setCurrentColumns,
        columnSets,
        createColumnSet,
        editColumnSet,
        deleteColumnSet,
        statusColumnSets,
        addCurrentColumn,
        removeCurrentColumn,
        viewMode,
        setViewMode,
        getMetadataIdsPage: handleGetMetadataIdsPage,
        getCurrentMetadataIds: handleGetCurrentMetadataIds,
      }),
      [
        loading,
        availableFilters,
        currentFilters,
        addCurrentFilter,
        filterSets,
        setCurrentFilters,
        updateCurrentFilter,
        removeCurrentFilterById,
        handleOnSearch,
        handleOnDownload,
        searchResult,
        pagination,
        allColumns,
        currentColumns,
        loadingSearch,
        loadingDownload,
        handleCreateFilterSet,
        deleteFilterSet,
        schema,
        setCurrentColumns,
        columnSets,
        createColumnSet,
        editColumnSet,
        deleteColumnSet,
        statusColumnSets,
        addCurrentColumn,
        removeCurrentColumn,
        viewMode,
        setViewMode,
        handleGetMetadataIdsPage,
        handleGetCurrentMetadataIds,
      ],
    );
  };

export const useAdvancedSearchContext = (): AdvancedSearchContextType => {
  return assert(
    useContext(AdvancedSearchContext),
    'AdvancedSearchContext: context expected',
  );
};
