import uniqueId from 'lodash/uniqueId';
import {Schemas} from '../../../../dto.generated';

export const bulkUpdate_abc123_unprocessed: Schemas.BulkUpdateStatusResponse = {
  action: {
    action: 'ADD',
    addValue: 'WZ',
  },
  field: {
    type: 'work',
    field: 'themes',
  },
  status: 'UNPROCESSED',
  items: {
    succeeded: [],
    failed: [],
    unprocessed: [
      'ece1a5df-8311-48be-a656-55b81ee946b5',
      '1834c531-4848-4428-a074-665c14b7446c',
      'f32b5395-e118-42c3-9c24-146a129064eb',
    ],
  },
  created: 1000 * 1736941559,
};

export const bulkUpdate_abc123_inprogress: Schemas.BulkUpdateStatusResponse = {
  action: {
    action: 'ADD',
    addValue: 'WZ',
  },
  field: {
    type: 'work',
    field: 'themes',
  },
  status: 'INPROGRESS',
  items: {
    succeeded: ['ece1a5df-8311-48be-a656-55b81ee946b5'],
    failed: [
      {
        id: '1834c531-4848-4428-a074-665c14b7446c',
        error: {
          key: 'CONFLICTING_TERM',
          description: 'Conflicting term',
        },
      },
    ],
    unprocessed: ['f32b5395-e118-42c3-9c24-146a129064eb'],
  },
  created: 1000 * 1718451959,
};

export const bulkUpdate_abc123_completed: Schemas.BulkUpdateStatusResponse = {
  action: {
    action: 'ADD',
    addValue: 'WZ',
  },
  field: {
    type: 'work',
    field: 'themes',
  },
  status: 'COMPLETED',
  items: {
    succeeded: [
      'ece1a5df-8311-48be-a656-55b81ee946b5',
      'f32b5395-e118-42c3-9c24-146a129064eb',
    ],
    failed: [
      {
        id: '1834c531-4848-4428-a074-665c14b7446c',
        error: {
          key: 'CONFLICTING_TERM',
          description: 'Conflicting term',
        },
      },
    ],
    unprocessed: [],
  },
  created: 1000 * 1710071159,
  completed: 1000 * 1710138659,
};

export const bulkUpdate_a2_completed: Schemas.BulkUpdateStatusResponse = {
  action: {
    action: 'REPLACE',
    currentValue: 'ABA',
    updatedValue: 'FFP',
  },
  field: {
    type: 'work',
    field: 'themes',
  },
  status: 'COMPLETED',
  items: {
    succeeded: [
      'ece1a5df-8311-48be-a656-55b81ee946b5',
      'f32b5395-e118-42c3-9c24-146a129064eb',
    ],
    failed: [
      {
        id: '1834c531-4848-4428-a074-665c14b7446c',
        error: {
          key: 'CONFLICTING_TERM',
          description: 'Conflicting term',
        },
      },
    ],
    unprocessed: [],
  },
  created: 1000 * 1610051159,
  completed: 1000 * 1615138659,
};

export const bulkUpdate_summary: Schemas.BulkUpdateSummary[] = [
  bulkUpdate_abc123_unprocessed,
  bulkUpdate_abc123_inprogress,
  bulkUpdate_abc123_completed,
  bulkUpdate_a2_completed,
]
  .map(m => ({
    ...m,
    id: 'asd' + uniqueId(),
    items: {
      unprocessed: m.items.unprocessed.length,
      failed: m.items.failed.length,
      succeeded: m.items.succeeded.length,
    },
  }))
  .sort((a, b) => {
    return a.created < b.created ? 1 : 0;
  });
