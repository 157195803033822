import {WorkHit} from '../types';
import {Schemas, SearchSchemas} from '../dto.generated';
import {SearchResult, SearchResultDto} from '../searchTypes';
import {mapHighlight} from './mapHighlights';
import {mapWorkFlattenedDto} from './mapWorkFlattenedDto';

export const mapWorkHits = (
  dto: SearchResultDto<SearchSchemas.WorkDocument>,
): SearchResult<WorkHit> => {
  return {
    // Mapping from ElasticDto to ApiDto. Then using the ApiDto mapping to out WorkMetadata dto (same as when calling work/{id}).
    hits: dto?.hits.hits.map(h =>
      mapWorkHit(mapWorkDocumentToWorkFlattenedDto(h._source)),
    ),
    highlights: dto?.hits.hits.map(h => mapHighlight(h.highlight)).flat(1),
    total: dto.hits.total.value,
  };
};

const mapWorkHit = (dto: Schemas.WorkFlattenedDto): WorkHit => {
  const workFlattened = mapWorkFlattenedDto(dto);

  return {
    ...workFlattened,
    id: workFlattened.work.id,
  };
};

export const mapWorkDocumentToWorkFlattenedDto = (
  dto: SearchSchemas.WorkDocument,
): Schemas.WorkFlattenedDto => {
  const {expressions, ...work} = dto;

  const mappedWork = mapWorkToWorkDto(work);
  const mappedExpressions = expressions?.map(e =>
    mapExpressionToExpressionDto(mappedWork.id, e),
  );

  const mappedManifestations = expressions
    ?.map(e => {
      return e.manifestations?.map(m =>
        mapManifestationToManifestationDto(e.id, m),
      );
    })
    .filter(a => a)
    .flat() as Schemas.ManifestationBaseDto[];

  return {
    work: mappedWork,
    expressions: mappedExpressions,
    manifestations: mappedManifestations,
  };
};

export const mapWorkToWorkDto = (
  dto: SearchSchemas.WorkDocument,
): Schemas.WorkBaseDto => {
  const {
    id,
    themeCodes,
    workToWorkRelations,
    workAgentRelations,
    useTitleAsMainEntry,
    norwegianForAdultImmigrants,
    generalNote,
    bibleContentCodes,
    bibleVersionCode,
    genreAndFormCodes,
    grepCodes,
    educationTargetGroupCodes,
    educationLevelCodes,
    subjectCodes,
    languageCodes,
    dewey,
    literatureTypeCodes,
    intellectualLevelCodes,
    preferredTitles,
    otherTitles,
    created,
    modified,
    type,
    agents,
    seriesRelations,
    firstPublishedYear,
  } = dto;
  return {
    id,
    created: created ? Number(created) : undefined,
    modified: modified ? Number(modified) : undefined,
    type: type ? (type as Schemas.WorkBaseDto['type']) : undefined,
    agents: mapAgentRoles(agents),
    preferredTitle: preferredTitles,
    titleInfo: {
      preferredTitle: preferredTitles,
      otherTitles:
        otherTitles?.map(oT => ({
          type: oT.type ?? '<missing>',
          value: oT.value ?? '<missing>',
          order: oT.order ?? 999,
        })) ?? [],
    },
    languages: languageCodes,
    intellectualLevel: intellectualLevelCodes,
    literatureType: literatureTypeCodes,
    dewey:
      dewey?.map(d => ({
        source: d.sourceCode,
        value: d.value,
      })) ?? [],
    themes: themeCodes,
    subjects: subjectCodes,
    educationLevel: educationLevelCodes,
    educationTargetGroup: educationTargetGroupCodes,
    grep: grepCodes,
    genreAndForm: genreAndFormCodes,
    bibleContent: bibleContentCodes,
    bibleVersion: bibleVersionCode,
    generalNote,

    norwegianForAdultImmigrants,
    useTitleAsMainEntry,
    seriesRelations: mapWorkSeries(seriesRelations),
    workRelations: mapWorkRelations(workToWorkRelations),
    agentRelations: mapAgentRelations(workAgentRelations),
    firstPublishedYear,
  };
};

export const mapExpressionToExpressionDto = (
  workId: string | undefined,
  dto: SearchSchemas.Expression,
): Schemas.ExpressionBaseDto => {
  const {created, modified, expressionFormat, agents, ...rest} = dto;
  return {
    workId,
    created: created ? Number(created) : undefined,
    modified: modified ? Number(modified) : undefined,
    expressionFormat:
      expressionFormat as unknown as Schemas.ExpressionBaseDto['expressionFormat'],
    agents: mapAgentRoles(agents),
    ...rest,
  };
};

export const mapManifestationToManifestationDto = (
  expressionId: string | undefined,
  dto: SearchSchemas.Manifestation,
): Schemas.ManifestationBaseDto => {
  const {
    created,
    modified,
    productGroupCode,
    productOwners,
    bookGroupCode,
    publishedYear,
    titles,
    edition,
    isbn,
    ean,
    status,
    productForm,
    imprints,
  } = dto;

  return {
    expressionId,
    id: dto.id,
    created: created ? Number(created) : undefined,
    modified: modified ? Number(modified) : undefined,
    status: (status ?? '<missing>') as Schemas.ManifestationBaseDto['status'],
    productForm: productForm?.productFormCode,
    // Skips unverifiedImprints.
    imprints: imprints?.verified ? imprints?.verified.map(mapImprint) : [],
    isbn,
    ean,
    edition,
    publishedYear,
    productOwners,
    mainTitle: titles?.mainTitles?.[0]?.value,
    productFormDetail: productForm?.productFormDetailCodes,
    bookGroup: bookGroupCode,
    productGroup: productGroupCode,
    agents: [],
    titleInfo: {
      mainTitles: (titles?.mainTitles ?? []).map(t => ({
        ...mapTitle(t),
        subTitles: (t.subTitles ?? []).map(tt => ({
          ...mapTitle(tt),
          parallelTitles: (tt.parallelTitles ?? []).map(mapTitle),
        })),
        parallelTitles: (t.parallelTitles ?? []).map(mapTitle),
      })),
      otherTitles:
        (titles?.otherTitles ?? []).map(t => ({
          ...mapTitle(t),
          type: t.type ?? '<missing>',
        })) ?? [],
    },
  };
};

const mapTitle = (dto: {
  value?: string;
  order?: number;
}): {value: string; order: number} => ({
  value: dto.value ?? '<missing>',
  order: dto.order ? Number(dto.value) : 0,
});

const mapAgentName = (dto: SearchSchemas.AgentName): Schemas.AgentNameDto => ({
  id: dto.id,
  name: dto?.name,
  surName: dto?.surName,
  regnalNumber: dto?.regnalNumber,
  addition: dto?.addition,
  subsidiary: dto?.subsidiary,
  nameOrder: dto?.nameOrder ?? 0,
});

const mapImprint = (dto: SearchSchemas.Imprint): Schemas.ImprintDto => {
  const firstAgentName = dto.agent?.agentNames?.[0];
  return {
    agentId: dto.agentId ?? '<missing>',
    agentName: firstAgentName
      ? mapAgentName(firstAgentName)
      : {
          name: '<missing>',
          nameOrder: 0,
        },
  };
};

const mapWorkSeries = (
  dto: SearchSchemas.WorkDocument['seriesRelations'],
): Schemas.WorkBaseDto['seriesRelations'] => {
  const series: Schemas.SeriesRelationDto[] = [];

  dto?.forEach(s => {
    series.push({
      entityId: s.seriesId ?? '<missing>',
      role: 'PART_OF',
      type: 'SERIES',
      title: s.series?.titles?.[0]?.title ?? '',
      numberInSeries: s.numberInSeries,
      // Map all titles?
    });
  });

  return series;
};

const mapWorkRelations = (
  dto: SearchSchemas.WorkDocument['workToWorkRelations'],
): Schemas.WorkBaseDto['workRelations'] => {
  const relations: Schemas.WorkToWorkRelationDto[] = [];

  dto?.forEach(s => {
    relations.push({
      entityId: s.relatedWorkId ?? '<missing>',
      type: 'WORK',
      role: (s.relationType ??
        '<missing>') as Schemas.WorkToWorkRelationDto['role'],
    });
  });

  return relations;
};

const mapAgentRelations = (
  dto: SearchSchemas.WorkDocument['workAgentRelations'],
): Schemas.WorkBaseDto['agentRelations'] => {
  const relations: Schemas.AgentRelationDto[] = [];

  dto?.forEach(s => {
    const firstAgentName = s.agent?.agentNames?.[0] ?? {
      nameOrder: 0,
      name: '<missing>',
    };

    relations.push({
      entityId: s.agentId ?? '<missing>',
      role: 'MENTIONS',
      type: (s.relationType as Schemas.AgentRelationDto['type']) ?? 'PERSON',
      agentName: {
        ...firstAgentName,
        nameOrder: Number(firstAgentName.nameOrder),
      },
      agentNationalId: firstAgentName.externalId,
    });
  });

  return relations;
};

const mapAgentRoles = (
  dto?: SearchSchemas.AgentRoles,
): Array<
  | Schemas.AgentRoleDto
  | Schemas.UnnamedAgentRoleDto
  | Schemas.UnverifiedAgentRoleDto
> => {
  if (!dto) {
    return [];
  }

  const verified: Schemas.AgentRoleDto[] = (dto.verified ?? []).map(v => {
    const firstAgentName = v.agent?.agentNames?.[0];

    return {
      roles: v.roleCodes ?? [],
      agentId: v.agentId ?? '<missing>',
      agentType: (v.agent?.agentType ??
        '<missing>') as Schemas.AgentRoleDto['agentType'],
      agentName: firstAgentName
        ? mapAgentName(firstAgentName)
        : {
            name: '<missing>',
            nameOrder: 0,
          },
      nationalId: v.agent?.nationalId,
    };
  });

  const unverified: Schemas.UnverifiedAgentRoleDto[] = (
    dto.unverified ?? []
  ).map(v => ({
    roles: v.roleCodes ?? [],
    name: v.name ?? '<missing>',
  }));

  const unnamed: Schemas.UnnamedAgentRoleDto[] = (dto.unnamed ?? []).map(v => ({
    roles: v.roleCodes ? v.roleCodes : [],
    unnamedAgentRole: v.unnamedRole ?? '<missing>',
    agentName: v.name ?? 'KI',
  }));

  return [...verified, ...unverified, ...unnamed];
};
