import assert from 'assert-ts';
import {MetadataId} from 'types';
import {Data} from 'schemaDefinition/types';

const getScalarValue = (data: Data, key: string): string | undefined => {
  if (!data[key]) {
    return undefined;
  }

  const val = data[key];

  if (Array.isArray(val)) {
    return typeof val[0] === 'string' ? val[0] : undefined;
  }

  return typeof val === 'string' ? val : undefined;
};

export const mapDataToMetadataId = (data: Data): MetadataId => {
  const workId = getScalarValue(data, 'workId');
  const ean = getScalarValue(data, 'ean');
  const manifestationId = getScalarValue(data, 'manifestationId');

  assert(
    workId || ean || manifestationId,
    'mapDataToMetadataId: Expected atleast one identifier',
  );

  return {
    workId: workId as string,
    ean,
    manifestationId,
  };
};
