import assert from 'assert-ts';
import {CodeList, CodeListRef} from 'api/types';

export const sortCodeList = (codelist: CodeList): CodeList => {
  switch (codelist.id) {
    case 'LANGUAGE':
      return sortLanguageCodeList(codelist);
    default:
      return codelist;
  }
};

const TOP_LANGUAGES = [
  CodeListRef.LANGUAGE['Bokmål'],
  CodeListRef.LANGUAGE['Nynorsk'],
  CodeListRef.LANGUAGE['Engelsk'],
  CodeListRef.LANGUAGE['Nordsamisk'],
  CodeListRef.LANGUAGE['Dansk'],
  CodeListRef.LANGUAGE['Svensk'],
];

type TopLanguage = (typeof TOP_LANGUAGES)[number];

const sortLanguageCodeList = (codelist: CodeList): CodeList => {
  return {
    ...codelist,
    codes: [
      ...TOP_LANGUAGES.map(lang =>
        assert(
          codelist.codes.find(code => code.code === lang),
          `Language code ${lang} not found`,
        ),
      ),
      ...codelist.codes.filter(
        code => !TOP_LANGUAGES.includes(code.code as TopLanguage),
      ),
    ],
  };
};
