import React from 'react';
import {Concept} from 'types';
import {BulkUpdate} from 'api/types';
import {BulkUpdateWorksPreview} from './BulkUpdateWorksPreview';

type Props = {
  bulkUpdate: BulkUpdate;
};

export const BulkUpdatePreview: React.FC<Props> = ({bulkUpdate}) => {
  return bulkUpdate.type === Concept.work ? (
    <BulkUpdateWorksPreview bulkUpdate={bulkUpdate} />
  ) : null;
};
