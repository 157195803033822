import React from 'react';
import {Alert} from '@mui/material';
import {useLocalization} from 'localization';
import {useBulkUpdateInProgress} from 'services/data';
import {Spacer} from 'components';

export const BulkUpdateInProgress = () => {
  const bulkupdateInProgress = useBulkUpdateInProgress();
  const {t} = useLocalization();

  if (bulkupdateInProgress) {
    return (
      <>
        <Alert severity={'warning'}>
          {t('page.bulkUpdate.inprogress.info')}
        </Alert>
        <Spacer height={2} />
      </>
    );
  }

  return null;
};
