import {useCallback} from 'react';
import {GridColDef} from '@mui/x-data-grid-pro';
import {Sorting} from '../types';
import {getUpdatedSorting} from '../functions';

export const useSorting = (
  sorting?: Sorting,
  onSortingChanged?: (sorting?: Sorting) => void,
) => {
  const handleToggleSort = useCallback(
    (event: {field: string; colDef: GridColDef}) => {
      if (event.colDef.sortable) {
        onSortingChanged &&
          onSortingChanged(getUpdatedSorting(event.field, sorting));
      }
    },
    [onSortingChanged, sorting],
  );

  return {
    sortModel: sorting ? [sorting] : undefined,
    handleToggleSort,
  };
};
