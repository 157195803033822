import {AgentSubType} from 'types';
import {Agent, GetTokens} from 'api/types';
import agentQuery from './data/agentQuery.json';
import {SearchSchemas} from './dto.generated';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpElasticV2} from './http/search/httpElastic';
import {mapAgentDto} from './mappers';
import {mapAgentDocumentToAgentDto} from './mappers/mapAgentDocumentToAgentDto';
import {mapHighlight} from './mappers/mapHighlights';
import {SearchResult, SearchResultDto} from './searchTypes';

export const searchAgents = (
  query: string,
  agentType: AgentSubType,
  page: number,
  size: number,
  getTokens: GetTokens,
  abortSignal?: AbortSignal,
  mock?: boolean,
): Promise<SearchResult<Agent>> => {
  /**
   * Placed the JSON in a file to easier debug it in elastic cloud console.
   */
  const elasticQuery = JSON.stringify(agentQuery)
    .replaceAll('${QUERY_PLACEHOLDER}', query)
    .replaceAll('${AGENT_TYPE_PLACEHOLDER}', agentType.toUpperCase());

  return exceptionToPromiseReject(() =>
    httpElasticV2<SearchResultDto<SearchSchemas.AgentDocument>>(
      getTokens,
      'ebs-agent-alias',
      JSON.parse(elasticQuery),
      page,
      size,
      abortSignal,
      mock,
    ).then(mapAgentHits),
  );
};

export const mapAgentHits = (
  dto: SearchResultDto<SearchSchemas.WorkDocument>,
): SearchResult<Agent> => {
  return {
    // Mapping from ElasticDto to ApiDto. Then using the ApiDto mapping to out Agent dto (same as when calling agent/{id}).
    hits: dto?.hits.hits.map(h =>
      mapAgentDto(mapAgentDocumentToAgentDto(h._source)),
    ),
    highlights: dto?.hits.hits.map(h => mapHighlight(h.highlight)).flat(1),
    total: dto.hits.total.value,
  };
};
