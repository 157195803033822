import React from 'react';
import {assert} from 'assert-ts';
import {CodeListsGate} from 'services/codeLists';
import {FlexBox} from 'components';
import {Preview, useSchemaCodelistIds} from 'schema';
import {entityTypeToSchemaReference} from 'schemas/functions/entityTypeToSchemaReference';
import {useEntitySchema, usePreviewConfiguration} from 'schemas/hooks';
import {EntityPreviewContent} from './types';

export const AgentPreviewContent: EntityPreviewContent = ({
  entityType,
  entityValue,
  configuration,
}) => {
  const schema = useEntitySchema(entityType);
  const codeLists = useSchemaCodelistIds(schema);

  const previewConfig = usePreviewConfiguration(
    entityTypeToSchemaReference(entityType),
    configuration,
  );

  return (
    <CodeListsGate codeLists={codeLists}>
      <FlexBox>
        <Preview
          schema={assert(schema, 'EntityPreview: schema is not defined')}
          data={entityValue}
          configuration={previewConfig}
        />
      </FlexBox>
    </CodeListsGate>
  );
};
