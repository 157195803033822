import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import assert from 'assert-ts';
import {BulkUpdateEntityType, Concept, MetadataId} from 'types';
import {useLocalization} from 'localization';
import {getResourcesBatched} from 'api/functions';
import {useSnacks} from 'components';
import {MASSUPDATE_BATCH_SIZE} from 'scenes/advancedSearch/consts';
import {setBulkUpdateItems} from '../actions';
import {mapMetadataIdByType} from '../mapper';

export const useCreateBulkUpdate = (
  type: BulkUpdateEntityType,
  total: number,
  searcher: (page: number, size: number) => Promise<MetadataId[]>,
) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {errorSnack} = useSnacks();
  const {t} = useLocalization();

  return useCallback((): Promise<void> => {
    if (type !== Concept.work) {
      assert(false, 'Not implemented, only work');
    }

    return getResourcesBatched(total, MASSUPDATE_BATCH_SIZE, searcher)
      .then(ids => {
        if (ids.length !== total) {
          errorSnack(
            t('page.bulkUpdate.error.count_mismatch', {
              loadCount: ids.length,
              searchCount: total,
            }),
          );

          return;
        }

        dispatch(
          setBulkUpdateItems({
            type: type,
            itemIds: mapMetadataIdByType(type, ids),
          }),
        );

        navigate(`/bulkUpdate/new`);
      })
      .catch(error => {
        errorSnack(t('page.bulkUpdate.error.load_failed'), error);
      });
  }, [dispatch, errorSnack, navigate, searcher, t, total, type]);
};
