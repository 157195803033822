import assert from 'assert-ts';
import {ViewMode} from 'api/types';
import {isViewMode} from './isViewMode';

export const assertViewMode = (value: string): ViewMode => {
  assert(
    isViewMode(value),
    `assertViewMode: Expected valid viewMode, got: "${value}"`,
    {value},
  );

  return value;
};
