import {ColumnConfig} from 'schema/table/types';

export const advancedSearchDefaultColumns: ColumnConfig[] = [
  {
    name: 'ean',
    visible: true,
    width: 300,
  },
  {
    name: 'workId',
    visible: true,
    width: 300,
  },
  {
    name: 'preferredTitles',
    visible: true,
    width: 300,
  },
];
