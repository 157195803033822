import React from 'react';
import {BulkUpdate} from 'api/types';
import {useLocalization} from 'localization';
import {Scene, Spacer} from 'components';
import {BulkUpdatePreview} from 'widgets';
import {useBulkUpdateTitle} from './hooks';

type Props = {
  bulkUpdate: BulkUpdate;
};

export const BulkUpdateDetailsSceneCore: React.FC<Props> = ({bulkUpdate}) => {
  const {t} = useLocalization();

  const itemCount =
    bulkUpdate.items.unprocessed.length +
    bulkUpdate.items.succeeded.length +
    bulkUpdate.items.failed.length;
  const title = useBulkUpdateTitle(bulkUpdate.type, itemCount);

  return (
    <Scene browserTitle={t('appbar.bulkUpdate')}>
      <Scene.Header title={title} />
      <Scene.Content loadStatus={'Loaded'}>
        <Spacer height={3} />
        <BulkUpdatePreview bulkUpdate={bulkUpdate} />
      </Scene.Content>
    </Scene>
  );
};
