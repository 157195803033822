import React from 'react';
import {CodeListsGate} from 'services/codeLists';
import {useAllCodeListIds} from 'services/codeLists/hooks/useAllCodeListIds';
import {AllThesaurusIds, ThesaurusesGate} from 'services/thesaurus';
import {BulkUpdateListSceneCore} from './BulkUpdateListSceneCore';

export const BulkUpdateListScene: React.FC = () => {
  const codelistIds = useAllCodeListIds();

  return (
    <ThesaurusesGate thesauruses={AllThesaurusIds}>
      <CodeListsGate codeLists={codelistIds}>
        <BulkUpdateListSceneCore />
      </CodeListsGate>
    </ThesaurusesGate>
  );
};
