import {OverviewTask} from 'api/types';
import {TableConfiguration} from 'schema/table/types';
import {defaultTableConfig} from 'schema/table/configuration/defaultTableConfig';
import {LockedBy} from 'schemas/renderers/table/LockedBy';
import {NoWrapped} from '../../renderers';
import {
  AvailabilityStatus,
  DateTime,
  Media,
  ProductForm,
  ProductOwner,
  TaskType,
} from '../../renderers/table';
import {overviewTaskDefaultColumns} from './columns/overviewTaskDefaultColumns';

export const overviewTaskTableConfig: TableConfiguration<OverviewTask> = {
  ...defaultTableConfig,
  defaultColumns: overviewTaskDefaultColumns,
  titleVariant: 'noHeaderAndSelectorEmbedded',
  partValueRenderers: {
    lockedBy: LockedBy,
    taskTypes: TaskType,
    productForm: ProductForm,
    availabilityStatus: AvailabilityStatus,
    media: Media,
    mainTitle: NoWrapped,
    productOwner: ProductOwner,
    registered: DateTime,
  },
  hidden: {
    productOwnerName: true,
    productOwnerCode: true,
    id: true,
    searchableTaskString: true,
  },
  partValueGetters: {
    media: (part, params) => {
      const value = params.value;
      if (Array.isArray(value)) {
        return value.map(v => v.subType);
      }
      return undefined;
    },
  },
  disableFiltering: false,
  disableFilteringByName: {
    taskTypes: true,
    productOwner: true,
  },
  enableQuickFilter: true,
  pageSizeAllowAll: true,
  hideFooter: false,
  quickFilters: [
    {
      title: 'overview.physical',
      filterModel: {
        items: [
          {
            id: 11111,
            field: 'productForm',
            operator: 'isAnyOf',
            value: ['BC', 'BE', 'BH', 'BL', 'BI', 'BJ', 'CB', 'CD'],
          },
          {
            id: 11112,
            field: 'contentReceivedDate',
            operator: 'isNotEmpty',
          },
        ],
      },
    },
    {
      title: 'overview.ebooks',
      filterModel: {
        items: [
          {
            id: 21111,
            field: 'contentReceivedDate',
            operator: 'isNotEmpty',
          },
          {
            id: 21112,
            field: 'productForm',
            operator: 'is',
            value: 'ED',
          },
        ],
      },
    },
    {
      title: 'overview.audiobooks',
      filterModel: {
        items: [
          {
            id: 31111,
            field: 'contentReceivedDate',
            operator: 'isNotEmpty',
          },
          {
            id: 31112,
            field: 'productForm',
            operator: 'is',
            value: 'AJ',
          },
        ],
      },
    },
    {
      title: 'overview.trykkunderlag',
      filterModel: {
        items: [
          {
            id: 41111,
            field: 'media',
            operator: 'is',
            value: ['CONTENT', 'COVER'],
          },
        ],
      },
    },
  ],
};
