import QueryString from 'qs';
import {MetadataId} from 'types';

export const createMetadataLink = ({ean, workId}: MetadataId) => {
  if (workId) {
    const queryParams = ean ? {isbn: ean} : {};

    return `/metadata/${workId}?${QueryString.stringify(queryParams, {
      skipNulls: true,
    })}`;
  }

  if (ean) {
    return `/metadata/ean/${ean}`;
  }

  return undefined;
};
