import {BulkUpdateSummary} from 'api/types';
import {TypedSchema} from 'schemaDefinition/types';
import {bulkUpdateCreateStaticSchema} from './bulkUpdateSchemas';

export const bulkUpdateSummarySchema: TypedSchema<BulkUpdateSummary> = {
  name: 'bulkUpdateSummary',
  parts: [
    {
      name: 'created',
      type: 'date',
    },
    {
      name: 'completed',
      type: 'date',
    },
    {
      type: 'codelist',
      name: 'status',
      codelistId: 'bulkUpdateStatus',
    },
    {
      name: 'type',
      type: 'codelist',
      codelistId: 'bulkUpdateType',
    },
    ...bulkUpdateCreateStaticSchema.parts,
    {
      name: 'currentValue',
      type: 'text',
    },
    {
      name: 'newValue',
      type: 'text',
    },
  ],
};
