import React, {useMemo} from 'react';
import {CodeListsGate} from 'services/codeLists';
import {useAllCodeListIds} from 'services/codeLists/hooks/useAllCodeListIds';
import {AllThesaurusIds, ThesaurusesGate} from 'services/thesaurus';
import {
  SceneExtensionsContext,
  useLinkedValueSearchSceneExtensionCore,
  useSceneExtensionsContextProviderValue,
  useThesaurusEditSceneExtensionCore,
} from 'sceneExtensions';
import {BulkUpdateNewSceneCore} from './BulkUpdateNewSceneCore';

export const BulkUpdateNewScene: React.FC = () => {
  const codelistIds = useAllCodeListIds();

  const thesaurusEditExtension = useThesaurusEditSceneExtensionCore();
  const linkedValueSearchExtension = useLinkedValueSearchSceneExtensionCore();
  const extensions = useMemo(
    () => [thesaurusEditExtension, linkedValueSearchExtension],
    [linkedValueSearchExtension, thesaurusEditExtension],
  );

  const sceneExtensionContext =
    useSceneExtensionsContextProviderValue(extensions);

  return (
    <ThesaurusesGate thesauruses={AllThesaurusIds}>
      <CodeListsGate codeLists={codelistIds}>
        <SceneExtensionsContext.Provider value={sceneExtensionContext}>
          <BulkUpdateNewSceneCore />
        </SceneExtensionsContext.Provider>
      </CodeListsGate>
    </ThesaurusesGate>
  );
};
