/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useCallback} from 'react';
import {assert} from 'assert-ts';
import {Concept} from 'types';
import {BulkUpdate, WorkV4} from 'api/types';
import {useLocalization} from 'localization';
import {getWorks} from '../../api';
import {useGetTokens} from '../../services/auth';
import {BulkUpdatePreviewCore, GetWorkItemLink} from './BulkUpdatePreviewCore';

type Props = {
  bulkUpdate: BulkUpdate;
};

export const BulkUpdateWorksPreview: React.FC<Props> = ({bulkUpdate}) => {
  const getTokens = useGetTokens();
  const {t} = useLocalization();

  assert(
    bulkUpdate.type === Concept.work,
    'BulkUpdateWorksPreview: type work is expected',
  );

  const getNavigationLink = useCallback<GetWorkItemLink>(
    (id, status) => {
      return {
        link: `/metadata/${id}`,
        tooltip: t(`page.bulkUpdate.details.work.link.${status}.tooltip`),
      };
    },
    [t],
  );

  const getItemId = useCallback((data: WorkV4) => {
    return data.id;
  }, []);

  const loader = useCallback(
    (ids: string[]) => {
      return getWorks(ids, getTokens, false).then(r => r.map(w => w.work));
    },
    [getTokens],
  );

  return (
    <BulkUpdatePreviewCore
      bulkUpdate={bulkUpdate}
      getWorkItemLink={getNavigationLink}
      getItemId={getItemId}
      loader={loader}
    />
  );
};
