import {Column, ColumnSet, FilterSet, ViewMode} from 'api/types';
import {Concept} from '../types';
import {
  AvailableFilter,
  DataResponse,
  Filter,
  GetTokens,
  Pageable,
} from './types';
import {Schemas} from './dto.generated';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpDelete, httpGet, httpPost, httpPut} from './http/ebba';
import {sortColumnSet} from './http/functions';
import {mapAvailableColumns, mapAvailableFilters} from './mappers';
import {mapFilterExportResponse} from './mappers/mapFilterExportResponse';
import {mapToFilters} from './mappers/mapFilters';
import {mapSavedFilter, mapSavedFilters} from './mappers/mapSavedFilters';
import {mapViewMode} from './mappers/mapViewMode';

export const getAvailableFilters = (
  getTokens: GetTokens,
  mock?: boolean,
): Promise<AvailableFilter[]> => {
  return exceptionToPromiseReject(() =>
    httpGet<Schemas.AvailableFilter[]>({
      subdir: 'advancedsearch/filters',
      mock,
      getTokens,
    }).then(mapAvailableFilters),
  );
};

/**
 * We first fetch all columns (type=MANIFESTATION is all columns),
 * then we fetch work specific columns, and map them to a boolean
 * in our column definition ("workColumn").
 */
export const getAvailableColumns = (
  getTokens: GetTokens,
  mock?: boolean,
): Promise<Column[]> => {
  return exceptionToPromiseReject(() =>
    httpGet<Schemas.Column[]>({
      subdir: 'advancedsearch/columns',
      queryParams: {type: mapViewMode(Concept.manifestation)},
      mock,
      getTokens,
    })
      .then(manifestationColumns => ({
        manifestationColumns,
        workColumns: httpGet<Schemas.Column[]>({
          subdir: 'advancedsearch/columns',
          queryParams: {type: mapViewMode(Concept.work)},
          mock,
          getTokens,
        }),
      }))
      .then(({manifestationColumns, workColumns}) =>
        workColumns.then(workColumnList =>
          mapAvailableColumns(manifestationColumns, workColumnList),
        ),
      ),
  );
};

export const postFilters = (
  getTokens: GetTokens,
  type: ViewMode,
  filters: Filter[],
  columns?: string[],
  eans?: string[],
  workIds?: string[],
  page?: Pageable['page'],
  size?: Pageable['size'],
  sort?: Pageable['sort'],
  mock?: boolean,
): Promise<DataResponse> => {
  const zeroPage = page ? page - 1 : 0;

  const apiFilters = mapToFilters(filters, false);

  // Api requires atleast one parameter
  if (apiFilters.length === 0 && eans?.length === 0 && workIds?.length === 0) {
    return Promise.resolve({
      hits: 0,
      rows: [],
    });
  }

  return exceptionToPromiseReject(() =>
    httpPost<Schemas.ExportInformationResponse, Schemas.ExportRequest>({
      subdir: 'advancedsearch/info',
      queryParams: {
        page: zeroPage,
        size: size ?? 20,
        sort: type === Concept.work ? undefined : sort,
      },
      mock,
      getTokens,
      body: {
        eans: eans ?? [],
        workIds: workIds ?? [],
        type: mapViewMode(type),
        filters: apiFilters,
        /*
          Columns:
            undefined: Returns default columns from backend
            []: Returns all columns
            ["col1", "col2"]: Returns specific columns
         */
        columns,
      },
    }).then(mapFilterExportResponse),
  );
};

export const postFiltersExport = (
  getTokens: GetTokens,
  type: ViewMode,
  filters: Filter[],
  columns?: string[],
  eans?: string[],
  workIds?: string[],
  sort?: Pageable['sort'],
  mock?: boolean,
): Promise<Blob> => {
  return exceptionToPromiseReject(() =>
    httpPost<Blob, Schemas.ExportRequest>({
      subdir: 'advancedsearch/export',
      queryParams: {
        sort: type === Concept.work ? undefined : sort,
      },
      mock,
      getTokens,
      body: {
        eans: eans ?? [],
        workIds: workIds ?? [],
        type: mapViewMode(type),
        filters: mapToFilters(filters, false),
        columns,
      },
      extraHeaders: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreatsheetml.sheet',
      },
    }),
  );
};

export const getSavedFilters = (
  getTokens: GetTokens,
  mock?: boolean,
): Promise<FilterSet[]> => {
  return exceptionToPromiseReject(() =>
    httpGet<Schemas.GetFilterSetsResponse, Schemas.ExportRequest>({
      subdir: 'advancedsearch/filters/set',
      mock,
      getTokens,
    }).then(response => mapSavedFilters(response.filters ?? [])),
  );
};

export const postSavedFilter = (
  getTokens: GetTokens,
  name: string,
  filters: Filter[],
  isPublic: boolean,
  columns?: string[],
  mock?: boolean,
): Promise<FilterSet> => {
  return exceptionToPromiseReject(() =>
    httpPost<Schemas.FilterSet, Schemas.CreateFilterSetRequest>({
      subdir: 'advancedsearch/filters/set',
      body: {
        name,
        filters: mapToFilters(filters, true),
        isPublic,
        columns,
      },
      mock,
      getTokens,
    }).then(response => mapSavedFilter(response)),
  );
};

export const deleteSavedFilter = (
  getTokens: GetTokens,
  id: string,
  mock?: boolean,
): Promise<void> => {
  return exceptionToPromiseReject(() =>
    httpDelete({
      subdir: 'advancedsearch/filters/set',
      body: id,
      mock,
      getTokens,
    }),
  );
};

export const getColumnSets = (
  getTokens: GetTokens,
  mock?: boolean,
): Promise<ColumnSet[]> => {
  return exceptionToPromiseReject(() =>
    httpGet<Schemas.GetColumnSetsResponse, Schemas.GetColumnSetsResponse>({
      subdir: 'advancedsearch/columns/set',
      mock,
      getTokens,
    })
      .then(response => response.columns ?? [])
      .then(columnSets => columnSets.sort(sortColumnSet)),
  );
};

export const postColumnSet = (
  getTokens: GetTokens,
  name: string,
  columns: string[],
  isPublic: boolean,
  mock?: boolean,
): Promise<ColumnSet> => {
  return exceptionToPromiseReject(() =>
    httpPost<Schemas.ColumnSet, Schemas.CreateColumnSetRequest>({
      subdir: 'advancedsearch/columns/set',
      body: {
        name,
        columns,
        isPublic,
      },
      mock,
      getTokens,
    }),
  );
};

export const putColumnSet = (
  getTokens: GetTokens,
  id: string,
  name: string,
  columns: string[],
  isPublic: boolean,
  mock?: boolean,
): Promise<ColumnSet> => {
  return exceptionToPromiseReject(() =>
    httpPut<Schemas.ColumnSet>({
      subdir: 'advancedsearch/columns/set',
      body: {
        id,
        name,
        columns,
        isPublic,
      },
      mock,
      getTokens,
    }),
  );
};

export const deleteColumnSet = (
  getTokens: GetTokens,
  id: string,
  mock?: boolean,
): Promise<void> => {
  return exceptionToPromiseReject(() =>
    httpDelete({
      subdir: 'advancedsearch/columns/set',
      body: id,
      mock,
      getTokens,
    }),
  );
};
