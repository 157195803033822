import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {AdvancedSearchViewModeState} from '../types';
import {setAdvancedSearchViewMode} from '../actions';

export const useSetAdvancedSearchViewMode = () => {
  const dispatch = useDispatch();
  return useCallback(
    (advancedSearchResponseType: AdvancedSearchViewModeState) => {
      dispatch(setAdvancedSearchViewMode(advancedSearchResponseType));
    },
    [dispatch],
  );
};
