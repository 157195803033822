import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {clearBulkUpdateItems} from '../actions';

export const useClearBulkUpdateItems = () => {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(clearBulkUpdateItems());
  }, [dispatch]);
};
