import React from 'react';
import {useParams} from 'react-router-dom';
import {CodeListsGate} from 'services/codeLists';
import {useAllCodeListIds} from 'services/codeLists/hooks/useAllCodeListIds';
import {useBulkUpdate} from 'services/data';
import {AllThesaurusIds, ThesaurusesGate} from 'services/thesaurus';
import {Scene} from 'components';
import {BulkUpdateDetailsSceneCore} from './BulkUpdateDetailsSceneCore';

export const BulkUpdateDetailsScene: React.FC = () => {
  const codelistIds = useAllCodeListIds();
  const {bulkUpdateId} = useParams();
  const {status, data} = useBulkUpdate(bulkUpdateId ?? 'missing bulkUpdateId');

  return (
    <ThesaurusesGate thesauruses={AllThesaurusIds}>
      <CodeListsGate codeLists={codelistIds}>
        {status === 'NotLoaded' ||
        status === 'Loading' ||
        status === 'Failed' ? (
          <Scene>
            <Scene.Header title="" />
            <Scene.Content
              loadStatus={status}
              error={
                status === 'Failed' ? 'Failed to load bulk update' : undefined
              }
            />
          </Scene>
        ) : (
          <BulkUpdateDetailsSceneCore bulkUpdate={data!} />
        )}
      </CodeListsGate>
    </ThesaurusesGate>
  );
};
