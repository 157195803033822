import {useCallback} from 'react';
import {Concept} from 'types';
import {Column, DataResponse, Filter, ViewMode} from 'api/types';
import {postFilters, postFiltersExport} from 'api/advancedsearch';
import {useGetTokens} from 'services/auth';
import {filterIsSearchable, getFilterValuesByName} from '../functions';

type SearcherType = {
  search: (
    type: ViewMode,
    filters: Filter[],
    columns?: string[],
    page?: number,
    size?: number,
    sort?: string,
  ) => Promise<DataResponse>;
  download: (
    type: ViewMode,
    filters: Filter[],
    columns?: string[],
    sort?: string,
  ) => Promise<Blob>;
};
export const useSearcher = (allColumns: Column[]): SearcherType => {
  const getTokens = useGetTokens();

  /**
   * If viewmode is WORK we must only send work columns.
   */
  const columnsByType = useCallback(
    (type: ViewMode, columns?: string[]) => {
      return type === Concept.manifestation
        ? columns
        : (columns ?? []).filter(
            column => allColumns.find(a => a.name === column)?.workColumn,
          );
    },
    [allColumns],
  );

  const search = useCallback(
    (
      type: ViewMode,
      filters: Filter[],
      columns?: string[],
      page?: number,
      size?: number,
      sort?: string,
    ) => {
      const eans = getFilterValuesByName('ean', filters, 'EQUAL');
      const workIds = getFilterValuesByName('workId', filters);

      return postFilters(
        getTokens,
        type,
        filters.filter(filterIsSearchable),
        columnsByType(type, columns),
        eans,
        workIds,
        page,
        size,
        sort,
      );
    },
    [columnsByType, getTokens],
  );

  const download = useCallback(
    (type: ViewMode, filters: Filter[], columns?: string[], sort?: string) => {
      const eans = getFilterValuesByName('ean', filters, 'EQUAL');
      const workIds = getFilterValuesByName('workIds', filters);

      return postFiltersExport(
        getTokens,
        type,
        filters.filter(filterIsSearchable),
        columnsByType(type, columns),
        eans,
        workIds,
        sort,
      );
    },
    [columnsByType, getTokens],
  );

  return {
    search,
    download,
  };
};
