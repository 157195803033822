import {useCallback} from 'react';
import {Concept} from 'types';
import {ViewMode} from 'api/types';
import {
  useAdvancedSearchViewMode,
  useSetAdvancedSearchViewMode,
} from 'services/advancedSearchViewMode';
import {CurrentColumnsType, ViewModeType} from '../types';

export const useViewMode = ({
  currentColumns,
  addCurrentColumn,
  removeCurrentColumn,
}: Pick<
  CurrentColumnsType,
  'currentColumns' | 'addCurrentColumn' | 'removeCurrentColumn'
>): ViewModeType => {
  const viewMode = useAdvancedSearchViewMode();
  const setViewMode = useSetAdvancedSearchViewMode();
  const handleViewModeChange = useCallback(
    (viewMode: ViewMode) => {
      if (viewMode === Concept.work) {
        if (!currentColumns.includes('workId')) {
          addCurrentColumn('workId');
        }
      }

      if (viewMode === Concept.manifestation) {
        if (!currentColumns.includes('ean')) {
          addCurrentColumn('ean');
        }
      }

      setViewMode(viewMode);
    },
    [currentColumns, addCurrentColumn, removeCurrentColumn, setViewMode],
  );

  return {
    viewMode,
    setViewMode: handleViewModeChange,
  };
};
