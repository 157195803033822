import {Schema} from 'schemaDefinition/types';

export function addErrorPartToSchema(schema: Schema): Schema {
  return {
    ...schema,
    parts: [
      ...schema.parts,
      {
        type: 'text',
        name: 'errorMessage',
      },
    ],
  };
}
