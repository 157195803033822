import assert from 'assert-ts';
import {BulkUpdateSummary} from '../types';
import {Schemas} from '../dto.generated';
import {mapBulkUpdateActionDto} from './mapBulkUpdateActionDto';
import {mapBulkUpdateWorkField} from './mapBulkUpdateWorkField';

export const mapBulkUpdateSummaryDto = (
  dto: Schemas.BulkUpdateSummary,
): BulkUpdateSummary => {
  return {
    id: dto.id,
    status: dto.status,
    items: dto.items,
    ...mapBulkUpdateWorkField(assert(dto.field)),
    ...mapBulkUpdateActionDto(assert(dto.action)),
    created: dto.created * 1000,
    completed: dto.completed ? dto.completed * 1000 : undefined,
  };
};
