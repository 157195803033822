import {CollectionSubType} from '../types';
import {Collection, GetTokens} from './types';
import seriesQuery from './data/seriesQuery.json';
import {SearchSchemas} from './dto.generated';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpElasticV2} from './http/search/httpElastic';
import {mapGetCollectionDto} from './mappers/mapCollectionDto';
import {mapToCollectionTypeDto} from './mappers/mapCollectionTypeDto';
import {mapHighlight} from './mappers/mapHighlights';
import {mapSeriesDocumentToSeriesWithRelationsDto} from './mappers/mapSeriesDocumentToSeriesWithRelationsDto';
import {SearchResult, SearchResultDto} from './searchTypes';

export const searchSeries = (
  query: string,
  seriesType: CollectionSubType,
  page: number,
  size: number,
  getTokens: GetTokens,
  abortSignal?: AbortSignal,
  mock?: boolean,
): Promise<SearchResult<Collection>> => {
  const type = mapToCollectionTypeDto(seriesType);

  /**
   * Placed the JSON in a file to easier debug it in elastic cloud console.
   */
  const elasticQuery = JSON.stringify(seriesQuery)
    .replaceAll('${QUERY_PLACEHOLDER}', query)
    .replaceAll('${SERIES_TYPE_PLACEHOLDER}', type);

  return exceptionToPromiseReject(() =>
    httpElasticV2<SearchResultDto<SearchSchemas.SeriesDocument>>(
      getTokens,
      'ebs-series-alias',
      JSON.parse(elasticQuery),
      page,
      size,
      abortSignal,
      mock,
    ).then(mapCollectionHits),
  );
};

export const mapCollectionHits = (
  dto: SearchResultDto<SearchSchemas.SeriesDocument>,
): SearchResult<Collection> => {
  return {
    // Mapping from ElasticDto to ApiDto. Then using the ApiDto mapping to out Collection dto (same as when calling agent/{id}).
    hits: dto?.hits.hits.map(h =>
      mapGetCollectionDto(mapSeriesDocumentToSeriesWithRelationsDto(h._source)),
    ),
    highlights: dto?.hits.hits.map(h => mapHighlight(h.highlight)).flat(1),
    total: dto.hits.total.value,
  };
};
