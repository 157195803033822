import {Filter, FilterOperation} from 'api/types';

export const getFilterValuesByName = (
  name: string,
  filters?: Filter[],
  operation?: FilterOperation,
): string[] | undefined => {
  const filter = filters?.find(f => f.name === name);
  if (filter) {
    if (operation) {
      if (filter.operation === operation) {
        return filter.values ?? [];
      }
    } else {
      return filter.values ?? [];
    }
  }

  return undefined;
};
