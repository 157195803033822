import React, {useMemo} from 'react';
import {useCodelist} from 'services/codeLists';
import {Layout, Select, Spacer} from 'components';
import {ViewModeType} from '../types';
import {assertViewMode} from '../functions';

type Props = {
  viewMode: ViewModeType['viewMode'];
  onChange: ViewModeType['setViewMode'];
};
export const SelectViewMode: React.FC<Props> = ({viewMode, onChange}) => {
  const codelist = useCodelist('bulkUpdateType');

  const options = useMemo(() => {
    return codelist.codes.map(c => ({
      value: assertViewMode(c.code),
      label: c.value,
    }));
  }, [codelist.codes]);

  return (
    <Layout horizontal adjustCenter>
      <Select value={viewMode} options={options} onChange={onChange} />
      <Spacer size={1} />
    </Layout>
  );
};
