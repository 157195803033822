import {useMemo} from 'react';
import {useLocalization} from 'localization';

export const useWizardStepLabels = (): string[] => {
  const {t} = useLocalization();
  return useMemo(
    () => [
      t('page.bulkUpdate.new.step.1.label'),
      t('page.bulkUpdate.new.step.2.label'),
    ],
    [t],
  );
};
