import {BulkUpdateFailedItem} from 'api/types';
import {Data} from 'schemaDefinition/types';

export function mapErrorsToData<TData extends Data = Data>(
  data: TData[],
  failedItems: BulkUpdateFailedItem[],
): TData[] {
  return data.map(i => {
    const error = failedItems.find(f => f.id === i.id);
    return {
      ...i,
      errorMessage: error?.error.key,
    };
  });
}
