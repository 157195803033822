import {Column} from '../types';
import {Schemas} from '../dto.generated';

export const mapAvailableColumns = (
  manifestationColumns: Schemas.Column[],
  workColumns: Schemas.Column[],
): Column[] => {
  return manifestationColumns.map(manifestationColumns =>
    mapAvailableColumn(manifestationColumns, workColumns),
  );
};

export const mapAvailableColumn = (
  dto: Schemas.Column,
  workColumns: Schemas.Column[],
): Column => {
  return {
    ...dto,
    codelistId: dto.codelistId as Schemas.CodelistDto['id'],
    workColumn: !!workColumns.find(w => w.name === dto.name),
  };
};
