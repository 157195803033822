import {useCallback} from 'react';
import {BulkUpdateCreate} from 'api';
import {postBulkUpdate} from 'api/bulkUpdate';
import {useGetTokens} from 'services/auth';

export const useSaveNewBulkUpdate = () => {
  const getTokens = useGetTokens();

  return useCallback(
    (bulkUpdate: BulkUpdateCreate, ids: string[]) => {
      return postBulkUpdate(bulkUpdate, ids, getTokens, false).then(r => r.id);
    },
    [getTokens],
  );
};
