import {BulkUpdateCreate} from '../types';
import {Schemas} from '../dto.generated';
import {mapToBulkUpdateActionDto} from './mapBulkUpdateActionDto';
import {mapToBulkUpdateWorkField} from './mapBulkUpdateWorkField';

export const mapToBulkUpdateWorkRequest = (
  data: BulkUpdateCreate,
  ids: string[],
): Schemas.BulkUpdateWorkRequest => {
  return {
    field: mapToBulkUpdateWorkField(data),
    action: mapToBulkUpdateActionDto(data),
    ids: ids,
  };
};
