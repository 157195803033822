import {useCallback, useEffect, useMemo, useState} from 'react';
import {Schema} from 'schemaDefinition/types';
import {ColumnWithLabel, useGetResource} from 'api';
import {getAvailableColumns} from 'api/advancedsearch';
import {useGetTokens} from 'services/auth';
import {useColumnConfigState} from 'schema/table/hooks/useColumnConfigState';
import {advancedSearchDefaultColumns} from 'schemas/configurations/table/columns';
import {CurrentColumnsType, advancedSearchSchemaName} from '../types';
import {mapDataColumnsToSchema} from '../mappers/mapDataColumnsToSchema';
import {useDataTranslations} from './useDataTranslations';

export const useCurrentColumns = (): CurrentColumnsType => {
  const getTokens = useGetTokens();

  const {
    columnConfigs,
    setColumnConfigsVisibilities,
    setColumnConfigVisibility,
  } = useColumnConfigState(advancedSearchSchemaName);

  const currentColumns = useMemo(() => {
    return (
      columnConfigs?.filter(c => c.visible).map(c => c.name) ??
      advancedSearchDefaultColumns.map(c => c.name)
    );
  }, [columnConfigs]);

  const setCurrentColumns = useCallback(
    (columns: string[]) => {
      setColumnConfigsVisibilities(columns, true);
    },
    [setColumnConfigsVisibilities],
  );

  const {getLabel} = useDataTranslations();
  const [schema, setSchema] = useState<Schema>({
    name: advancedSearchSchemaName,
    parts: advancedSearchDefaultColumns.map(column => ({
      name: column.name,
      type: 'text',
    })),
  });

  const handleGetAllColumns = useCallback(
    () => getAvailableColumns(getTokens),
    [getTokens],
  );

  const {status: statusAllColumns, data: allColumns} =
    useGetResource(handleGetAllColumns);

  // Update schema, when column selection changes.
  useEffect(() => {
    if (allColumns !== undefined) {
      setSchema(
        mapDataColumnsToSchema(
          advancedSearchSchemaName,
          allColumns,
          currentColumns,
        ),
      );
    }
  }, [allColumns, currentColumns]);

  const handleAddCurrentColumn = useCallback(
    (column: string) => {
      setColumnConfigVisibility(column, true);
    },
    [setColumnConfigVisibility],
  );

  const handleRemoveCurrentColumn = useCallback(
    (column: string) => {
      setColumnConfigVisibility(column, false);
    },
    [setColumnConfigVisibility],
  );

  const allColumnsWithLabel: ColumnWithLabel[] = useMemo(() => {
    return (
      allColumns
        ?.map(d => {
          return {
            ...d,
            label: getLabel(d.name),
          };
        })
        .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0)) ??
      []
    );
  }, [allColumns, getLabel]);

  return useMemo(() => {
    return {
      schema,
      allColumns: allColumnsWithLabel,
      loadingColumns: statusAllColumns === 'Loading',
      currentColumns,
      addCurrentColumn: handleAddCurrentColumn,
      removeCurrentColumn: handleRemoveCurrentColumn,
      setCurrentColumns,
    };
  }, [
    allColumnsWithLabel,
    currentColumns,
    handleAddCurrentColumn,
    handleRemoveCurrentColumn,
    schema,
    setCurrentColumns,
    statusAllColumns,
  ]);
};
