import {ThesaurusNode} from 'api/types';
import {DataValue, PartThesaurus} from 'schemaDefinition/types';
import {sortThemaCodes} from 'services/data/metadata/functions/sortThemaCodes';
import {formatNodeTitle} from 'services/thesaurus/functions/formatNodeTitle';
import {findThesaurusNode} from '../../table/functions/findThesaurusNode';
import {formatNullValue} from './formatNullValue';

export const formatThesaurusValue = (
  value: DataValue,
  part: PartThesaurus,
  thesaurus: ThesaurusNode,
): string => {
  if (
    value === undefined ||
    value === null ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return formatNullValue();
  }

  const codes = Array.isArray(value) ? value : [value];

  const sortedCodes =
    part.thesaurusId === 'thema'
      ? sortThemaCodes(codes as string[]) ?? codes
      : codes;

  if (part.showCode === 'onlyCode') {
    return sortedCodes.join(', ');
  }

  return sortedCodes
    .map(code => {
      const node = findThesaurusNode(code, thesaurus);
      if (node) {
        return formatNodeTitle(node, part.showCode, part.selectableNodeTypes);
      }
    })
    .join(', ');
};
