import {BulkUpdateEntityType} from 'types';
import {useLocalization} from 'localization';

export const useBulkUpdateTitle = (
  type: BulkUpdateEntityType,
  count: number,
) => {
  const {t} = useLocalization();
  return t(`page.bulkUpdate.${type}.title`, {count});
};
