import React, {useCallback, useMemo, useState} from 'react';
import {Checkbox, FormControlLabel, Grid} from '@mui/material';
import {Box} from '@mui/system';
import {Concept} from 'types';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {useShortcut} from 'shortcuts';
import {
  Accordion,
  ActionButton,
  FlexBox,
  IconButton,
  Layout,
  Spacer,
  Text,
  TextField,
  TextMatch,
} from 'components';
import {useAdvancedSearchContext} from '../context/AdvancedSearchContext';
import {useFilteredColumns} from '../hooks';
import {ColumnSets} from './ColumnSets';

export const SingleColumnSelectionAccordion: React.FC = () => {
  const {t} = useLocalization();
  const [isExpanded, setIsExpanded] = useState(false);
  const [columnsFilter, setColumnsFilter] = useState<string>();
  const {
    allColumns,
    currentColumns,
    setCurrentColumns,
    addCurrentColumn,
    removeCurrentColumn,
    viewMode,
  } = useAdvancedSearchContext();

  const filteredColumns = useFilteredColumns(
    columnsFilter,
    allColumns,
    currentColumns,
  );

  const REQUIRED_COLUMNS = useMemo(() => {
    return viewMode === Concept.work ? ['workId'] : ['ean'];
  }, [viewMode]);

  const toggleCurrentColumn = useCallback(
    (column: string, checked: boolean) => {
      if (!checked) {
        removeCurrentColumn(column);
      } else {
        addCurrentColumn(column);
      }
    },
    [removeCurrentColumn, addCurrentColumn],
  );

  const unselectAll = useCallback(() => {
    setCurrentColumns(REQUIRED_COLUMNS);
  }, [REQUIRED_COLUMNS, setCurrentColumns]);

  // Handle column filter short cut
  // - focus filter input ref
  const filterInputRef = React.useRef<HTMLInputElement>(null);

  // - callback to expand accordion and set focus on filter input
  const focusFilter = useCallback(() => {
    setIsExpanded(true);
    // Finish handling event before focusing
    setTimeout(() => filterInputRef.current?.focus());
  }, []);

  // - register short cut
  useShortcut('focusColumnsFilter', focusFilter);

  return (
    <Accordion
      colorx={ColorPalette.lightBeige}
      unmountOnExit
      expanded={isExpanded}
      onChange={setIsExpanded}>
      <Accordion.Header>
        <Layout horizontal width={'100%'}>
          <Layout horizontal adjustCenter adjustLeft>
            <Spacer size={0.5} />
            <Text variant="h4">{`${currentColumns.length} valgte kolonner`}</Text>
          </Layout>
        </Layout>
      </Accordion.Header>
      <Accordion.Content>
        <Box
          component={Grid}
          container
          spacing={4}
          padding={0}
          flex={1}
          flexDirection={'row'}>
          <Grid item xs={6} sm={3}>
            <ColumnSets />
          </Grid>

          <Grid item xs={6} sm={9}>
            {/* Columns filter */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FlexBox horizontal>
                <FlexBox horizontal flex={1}>
                  <TextField
                    inputRef={filterInputRef}
                    type={'text'}
                    name={'name'}
                    flex={1}
                    value={columnsFilter}
                    placeholder={t('page.search.columns.filter.placeholder')}
                    aria-autocomplete={'none'}
                    autoComplete={'off'}
                    InputProps={{
                      'aria-autocomplete': 'none',
                      autoComplete: 'off',
                      endAdornment: (
                        <IconButton
                          icon="CloseSmall"
                          onClick={() => setColumnsFilter(undefined)}
                        />
                      ),
                    }}
                    onChange={e =>
                      setColumnsFilter(e.target.value ?? undefined)
                    }
                  />
                  <Spacer size={3} />
                </FlexBox>
                <FlexBox horizontal flex={1}>
                  <ActionButton
                    icon={'RemoveCircle'}
                    title={t('page.search.columns.unselectAll.label')}
                    onClick={unselectAll}
                    disabled={currentColumns.every(c =>
                      REQUIRED_COLUMNS.includes(c),
                    )}
                    showSpinnerOnClick={true}
                  />
                </FlexBox>
              </FlexBox>
            </Grid>
            {/* Columns, all or filtered */}
            <Layout horizontal alignItems={'center'} p={1}>
              <Text variant={'body1'}>{t('page.search.columns.title')}</Text>
            </Layout>
            <Grid container flex={1} flexDirection={'row'} spacing={1}>
              {filteredColumns.map(column => (
                <Grid item xs={12} sm={4} md={3} lg={2} key={column.name}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          !!(currentColumns ?? []).find(c => c === column.name)
                        }
                        onChange={event => {
                          toggleCurrentColumn(
                            column.name,
                            event.target.checked,
                          );
                        }}
                        inputProps={{
                          'aria-label': column.label,
                        }}
                        disabled={REQUIRED_COLUMNS.includes(column.name)}
                      />
                    }
                    label={
                      <TextMatch match={columnsFilter} variant="label">
                        {column.label}
                      </TextMatch>
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Accordion.Content>
    </Accordion>
  );
};
