import assert from 'assert-ts';
import {Column} from 'api/types';
import {Part, Schema} from 'schemaDefinition/types';

export const mapDataColumnsToSchema = (
  schemaName: string,
  allColumns: Column[],
  currentColumns: string[],
): Schema => {
  return {
    name: schemaName,
    parts: currentColumns.map(cc => {
      const currentColumn = allColumns.find(ac => ac.name === cc);

      return mapColumnPart(
        assert(
          currentColumn,
          `mapDataColumnsToSchema: Unexpected column: ${cc}`,
          {cc},
        ),
      );
    }),
  };
};

const mapColumnPart = (column: Column): Part => {
  switch (column.type) {
    case 'TEXT':
      if (column.codelistId) {
        return {
          type: 'codelist',
          codelistId: column.codelistId,
          name: column.name,
        };
      }

      if (column.name === 'themas') {
        return {
          name: column.name,
          type: 'thesaurus',
          thesaurusId: 'thema',
          cardinality: 'multiple',
        };
      }

      if (column.name === 'themasCodes') {
        return {
          name: column.name,
          type: 'thesaurus',
          thesaurusId: 'thema',
          cardinality: 'multiple',
          showCode: 'onlyCode',
        };
      }

      if (column.name === 'subjects') {
        return {
          name: column.name,
          type: 'thesaurus',
          thesaurusId: 'bokbasen',
          cardinality: 'multiple',
        };
      }

      if (column.name === 'genreAndForm') {
        return {
          name: column.name,
          type: 'thesaurus',
          thesaurusId: 'genreandform',
          cardinality: 'multiple',
        };
      }

      return {
        name: column.name,
        type: 'text',
      };
    case 'NUMBER':
      return {
        name: column.name,
        type: 'int',
      };
    case 'DATE':
    case 'DATETIME':
      return {
        name: column.name,
        type: 'date',
      };
    case 'YEAR':
      return {
        name: column.name,
        type: 'year',
      };
    case 'PRICE':
      return {
        name: column.name,
        type: 'text',
      };
    default:
      assert.soft(
        false,
        `mapDataColumnsToSchema: Unexpected column type: ${column.type}`,
        column,
      );
      return {
        name: column.name,
        type: 'text',
      };
  }
};
