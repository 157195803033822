import {Concept} from 'types';
import {valueReducerFactory} from 'services/utils/redux/valueReducerFactory';
import {AdvancedSearchViewModeState} from '../types';

const [
  ADVANCED_SEARCH_VIEW_MODE_KEY,
  advancedSearchViewModeReducer,
  setAdvancedSearchViewMode,
  clearAdvancedSearchViewMode,
  useAdvancedSearchViewMode,
] = valueReducerFactory(
  `advancedSearchViewMode`,
  Concept.manifestation as AdvancedSearchViewModeState,
);

export type AdvancedSearchViewModeAction =
  | ReturnType<typeof setAdvancedSearchViewMode>
  | ReturnType<typeof clearAdvancedSearchViewMode>;

export {
  ADVANCED_SEARCH_VIEW_MODE_KEY,
  advancedSearchViewModeReducer,
  setAdvancedSearchViewMode,
  clearAdvancedSearchViewMode,
  useAdvancedSearchViewMode,
};
