import {useCallback, useMemo} from 'react';
import {Data} from 'schemaDefinition/types';
import {useGetResourceListByIds} from 'api';
import {getOffset} from 'schema/table/functions';
import {usePaginationState} from 'feature';

export function useDataByIdPagination<TData extends Data = Data>(
  ids: string[],
  loader: (ids: string[]) => Promise<TData[]>,
) {
  const pageSize = 10;
  const pagination = usePaginationState(pageSize, undefined, ids.length);
  const {page} = pagination;

  const itemIds = useMemo(() => {
    const offset = getOffset(pageSize, page);
    return ids.slice(offset, offset + pageSize);
  }, [ids, page]);

  const load = useCallback(
    (pageIds: string[]) => {
      return pageIds.length ? loader(pageIds) : Promise.reject();
    },
    [loader],
  );

  const {data, status} = useGetResourceListByIds<TData>(itemIds, load);

  return {
    pagination,
    data: data ?? [],
    status,
  };
}
