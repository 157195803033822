import {useMemo} from 'react';
import assert from 'assert-ts';
import {Concept} from 'types';
import {BulkUpdateAction, BulkUpdateCreate} from 'api/types';
import {Part, Schema, Separator} from 'schemaDefinition/types';
import {findSchemaPart, modifyValidations} from 'schemaDefinition';
import {useSchema} from 'schemas';

const EMPTY_SCHEMA: Schema = {
  name: 'DUMMY_EMPTY',
  parts: [],
};

const makeOperationSchema = (
  operation: BulkUpdateAction,
  part: Part,
): Schema => {
  switch (operation) {
    case 'ADD': {
      return {
        name: 'addOperationSchema',
        parts: [
          Separator('card', 'card', 'addValue'),
          {
            ...part,
            name: 'newValue',
            required: true,
            labelKey: part.labelKey ?? part.name,
            listValidation: modifyValidations(part.listValidation, {
              messageKey: 'bulkUpdate.add.singleValue',
              maxLength: 1,
            }),
          } as Part,
        ],
      };
    }
    case 'REMOVE': {
      return {
        name: 'removeOperationSchema',
        parts: [
          Separator('card', 'card', 'removeValue'),
          {
            ...part,
            name: 'currentValue',
            required: true,
            labelKey: part.labelKey ?? part.name,
            listValidation: modifyValidations(part.listValidation, {
              messageKey: 'bulkUpdate.remove.singleValue',
              maxLength: 1,
            }),
          } as Part,
        ],
      };
    }
    case 'REPLACE': {
      return {
        name: 'replaceOperationSchema',
        parts: [
          Separator('card', 'card', 'replaceCurrentValue'),
          {
            ...part,
            name: 'currentValue',
            required: true,
            labelKey: part.labelKey ?? part.name,
            listValidation: modifyValidations(part.listValidation, {
              messageKey: 'bulkUpdate.replace.singleValue',
              maxLength: 1,
            }),
          } as Part,
          Separator('card', 'card', 'replaceNewValue'),
          {
            ...part,
            name: 'newValue',
            required: true,
            labelKey: part.labelKey ?? part.name,
            listValidation: modifyValidations(part.listValidation, {
              messageKey: 'bulkUpdate.replace.singleValue',
              maxLength: 1,
            }),
          } as Part,
        ],
      };
    }
  }
};

export type BulkUpdateValuesProps = Pick<
  Partial<BulkUpdateCreate>,
  'type' | 'bulkUpdateField' | 'bulkUpdateAction'
>;

export const useBulkUpdateValuesSchema = ({
  type,
  bulkUpdateField: field,
  bulkUpdateAction: action,
}: BulkUpdateValuesProps): Schema => {
  const workSchema = useSchema(Concept.work);
  const manifestationSchema = useSchema(Concept.manifestation);

  return useMemo(() => {
    if (!field || !action) {
      return EMPTY_SCHEMA;
    }

    const fullSchema = type === Concept.work ? workSchema : manifestationSchema;
    const fieldPart = assert(
      findSchemaPart(field, fullSchema),
      `useBulkUpdateSchema: fieldPart not found: ${type}: ${field}`,
    );

    return makeOperationSchema(action, fieldPart);
  }, [field, manifestationSchema, action, type, workSchema]);
};
