import {useCallback, useEffect, useState} from 'react';
import {BulkUpdateSummary, DataLoadStatus, HttpError} from 'api';
import {getBulkUpdates} from 'api/bulkUpdate';
import {useGetTokens} from 'services/auth';
import {usePaginationState} from 'feature';

export const useBulkUpdates = () => {
  const getTokens = useGetTokens();

  const [result, setResult] = useState<DataLoadStatus<BulkUpdateSummary[]>>({
    status: 'NotLoaded',
  });
  const pagination = usePaginationState(20);

  const get = useCallback(
    (page: number, size: number) => {
      return getBulkUpdates(getTokens, page, size);
    },
    [getTokens],
  );

  useEffect(() => {
    setResult(old => ({status: 'Loading', data: old.data}));
    get(pagination.page, pagination.size)
      .then(data => {
        setResult(() => ({status: 'Loaded', data: data.items}));
        pagination.resetPage(data.elements, pagination.page);
      })
      .catch((error: HttpError) => {
        setResult(() => ({status: 'Failed', error}));
      });
    // Ignore updates on pagination/resetPage/total change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get, pagination.page, pagination.size]);

  return {
    ...result,
    pagination,
  };
};
