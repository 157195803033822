import assert from 'assert-ts';
import {Concept} from 'types';
import {BulkUpdateCreate} from '../types';
import {Schemas} from '../dto.generated';

export const mapBulkUpdateWorkField = (
  dto: Schemas.BulkUpdateWorkField,
): Pick<BulkUpdateCreate, 'type' | 'bulkUpdateField'> => {
  if (dto.type === 'WORK') {
    return {
      type: Concept.work,
      bulkUpdateField: dto.field,
    };
  }

  assert(false, 'mapBulkUpdateWorkField: Unexpected type', {dto});
};

export const mapToBulkUpdateWorkField = (
  dto: Pick<BulkUpdateCreate, 'type' | 'bulkUpdateField'>,
): Schemas.BulkUpdateWorkField => {
  if (dto.type === Concept.work) {
    return {
      type: 'WORK',
      field: dto.bulkUpdateField as 'genreAndForm' | 'subjects' | 'themes',
    };
  }

  assert(false, 'mapToBulkUpdateWorkField: Unexpected type', {dto});
};
